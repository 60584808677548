export const PICTURE_MAX_SIZE = 2000;
export const PARTNER_PICTURES_MAX_SIZE = 10000;
export const PICTURE_TYPE_POSTERS = 'posters';
export const PICTURE_TYPE_THUMBNAILS = 'thumbnails';
export const PICTURE_TYPE_XL_POSTERS = 'xl_posters';
export const PICTURE_TYPE_XL_THUMBNAILS = 'xl_thumbnails';
export const PICTURE_TYPE_BANNERS = 'banners';
export const PICTURE_TYPE_MOBILE_BANNERS = 'mobile_banners';
export const PICTURE_TYPE_HALF_BANNERS = 'half_banners';
export const PICTURE_TYPE_ROUNDEDS = 'roundeds';
export const PICTURE_TYPE_PERSONS = 'persons';
export const PICTURE_TYPE_AVATARS = 'avatars';
export const PICTURE_TYPE_LOGOS = 'logos';
export const PICTURE_TYPE_HEADER_LOGOS = 'header_logos';
export const PICTURE_TYPE_FOOTER_LOGOS = 'footer_logos';
export const PICTURE_TYPE_SQUARE_LOGOS = 'square_logos';
export const PICTURE_TYPE_FAVICONS = 'favicons';
export const PICTURE_TYPE_PICTOGRAMS = 'pictograms';
export const PICTURE_TYPE_BACKDROPS = 'backdrops';
export const PICTURE_TYPE_CONTENT_BACKDROPS = 'content_backdrops';
export const PICTURE_TYPE_CONTENT_BACKDROPS_DESKTOP = 'content_backdrops_desktop';
export const PICTURE_TYPE_CONTENT_BACKDROPS_TABLET = 'content_backdrops_tablet';
export const PICTURE_TYPE_CONTENT_BACKDROPS_MOBILE = 'content_backdrops_mobile';
export const PICTURE_TYPE_CONTENT_BACKDROPS_ORANGE = 'content_backdrops_orange';
export const PICTURE_TYPE_CONTENT_BACKDROPS_FREE = 'content_backdrops_free';
export const PICTURE_TYPE_CONTENT_BACKDROPS_APPLE_TV = 'content_backdrops_apple_tv';
export const PICTURE_TYPE_CONTENT_BACKDROPS_ANDROID_TV = 'content_backdrops_android_tv';
export const PICTURE_TYPE_CONTENT_BACKDROPS_SAMSUNG_TV = 'content_backdrops_samsung_tv';
export const PICTURE_TYPE_LG_BACKDROPS = 'lg_backdrops';
export const PICTURE_TYPE_MD_BACKDROPS = 'md_backdrops';
export const PICTURE_TYPE_SM_BACKDROPS = 'sm_backdrops';
export const PICTURE_TYPE_LG_TV_BACKDROPS = 'lg_tv_backdrops';
export const PICTURE_TYPE_MD_TV_BACKDROPS = 'md_tv_backdrops';
export const PICTURE_TYPE_SM_TV_BACKDROPS = 'sm_tv_backdrops';
export const PICTURE_TYPE_LG_TABLET_BACKDROPS = 'lg_tablet_backdrops';
export const PICTURE_TYPE_MD_TABLET_BACKDROPS = 'md_tablet_backdrops';
export const PICTURE_TYPE_SM_TABLET_BACKDROPS = 'sm_tablet_backdrops';
export const PICTURE_TYPE_MD_MOBILE_BACKDROPS = 'md_mobile_backdrops';
export const PICTURE_TYPE_LANDSCAPE_MOBILE_BACKDROPS =
  'landscape_mobile_backdrops';
export const PICTURE_TYPE_FULLSCREENS = 'fullscreens';
export const PICTURE_TYPE_PORTRAIT_FULLSCREENS = 'portrait_fullscreens';
export const PICTURE_TYPE_OPENGRAPH = 'opengraph';
export const PICTURE_TYPE_AVOD_BANNERS = 'avod_banners';
export const PICTURE_TYPE_DESKTOP_AVOD_BANNERS = 'desktop_avod_banners';
export const PICTURE_TYPE_TABLET_AVOD_BANNERS = 'tablet_avod_banners';
export const PICTURE_TYPE_MOBILE_AVOD_BANNERS = 'mobile_avod_banners';
export const PICTURE_TYPE_AVOD_TOP_BANNERS = 'avod_top_banners';
export const PICTURE_TYPE_DESKTOP_AVOD_TOP_BANNERS = 'desktop_avod_top_banners';
export const PICTURE_TYPE_TABLET_AVOD_TOP_BANNERS = 'tablet_avod_top_banners';
export const PICTURE_TYPE_MOBILE_AVOD_TOP_BANNERS = 'mobile_avod_top_banners';
export const PICTURE_TYPE_AVOD_INTERSTITIALS = 'avod_interstitial';
export const PICTURE_TYPE_TV_AVOD_INTERSTITIALS = 'tv_avod_interstitial';
export const PICTURE_TYPE_TABLET_AVOD_INTERSTITIALS =
  'tablet_avod_interstitial';
export const PICTURE_TYPE_MOBILE_AVOD_INTERSTITIALS =
  'mobile_avod_interstitial';
export const PICTURE_TYPE_AVOD_BLOCKS = 'avod_blocks';
export const PICTURE_TYPE_TV_AVOD_BLOCKS = 'tv_avod_blocks';
export const PICTURE_TYPE_DESKTOP_AVOD_BLOCKS = 'desktop_avod_blocks';
export const PICTURE_TYPE_TABLET_AVOD_BLOCKS = 'tablet_avod_blocks';
export const PICTURE_TYPE_MOBILE_AVOD_BLOCKS = 'mobile_avod_blocks';

// partners
export const PICTURE_TYPE_BOX_ART = 'box_art';
export const PICTURE_TYPE_SEASON_BOX_ART = 'season_box_art';
export const PICTURE_TYPE_COVER_ART = 'cover_art';
export const PICTURE_TYPE_HERO_ART = 'hero_art';
export const PICTURE_TYPE_TITLE_ART = 'title_art';
export const PICTURE_TYPE_POSTER_ART = 'poster_art';
export const PICTURE_TYPE_BETV_POSTER = 'betv_poster';
export const PICTURE_TYPE_MOLOTOV_POSTER = 'molotov_poster';

export const PICTURE_LABEL_POSTERS = 'pictures.type.poster.label';
export const PICTURE_LABEL_THUMBNAILS = 'pictures.type.thumbnail.label';
export const PICTURE_LABEL_XL_POSTERS = 'pictures.type.posterXl.label';
export const PICTURE_LABEL_XL_THUMBNAILS = 'pictures.type.thumbnailXl.label';
export const PICTURE_LABEL_BANNERS = 'pictures.type.banner.label';
export const PICTURE_LABEL_MOBILE_BANNERS = 'pictures.type.mobileBanner.label';
export const PICTURE_LABEL_HALF_BANNERS = 'pictures.type.halfBanner.label';
export const PICTURE_LABEL_ROUNDEDS = 'pictures.type.rounded.label';
export const PICTURE_LABEL_AVATARS = 'pictures.type.avatar.label';
export const PICTURE_LABEL_LOGOS = 'pictures.type.logo.label';
export const PICTURE_LABEL_FAVICONS = 'pictures.type.favicon.label';
export const PICTURE_LABEL_PICTOGRAMS = 'pictures.type.pictogram.label';
export const PICTURE_LABEL_BACKDROPS = 'pictures.type.backdrop.label';
export const PICTURE_LABEL_BASE_BACKDROPS = 'pictures.type.baseBackdrop.label';
export const PICTURE_LABEL_MOBILE_BACKDROPS =
  'pictures.type.mobileBackdrop.label';
export const PICTURE_LABEL_FULLSCREENS = 'pictures.type.fullscreen.label';
export const PICTURE_LABEL_PORTRAIT_FULLSCREENS =
  'pictures.type.portraitFullscreen.label';
export const PICTURE_LABEL_OPENGRAPH = 'pictures.type.opengraph.label';
export const PICTURE_LABEL_CAST = 'pictures.type.cast.label';

// partners
export const PICTURE_LABEL_BOX_ART = 'pictures.type.boxArt.label';
export const PICTURE_LABEL_SEASON_BOX_ART = 'pictures.type.seasonBoxArt.label';
export const PICTURE_LABEL_COVER_ART = 'pictures.type.coverArt.label';
export const PICTURE_LABEL_HERO_ART = 'pictures.type.heroArt.label';
export const PICTURE_LABEL_TITLE_ART = 'pictures.type.titleArt.label';
export const PICTURE_LABEL_POSTER_ART = 'pictures.type.posterArt.label';
export const PICTURE_LABEL_BETV_POSTER = 'pictures.type.betvPoster.label';
export const PICTURE_LABEL_MOLOTOV_POSTER = 'pictures.type.molotovPoster.label';


export const picturesDefaultMimeTypes = [
  'image/jpeg',
  'image/png',
  'image/gif',
];

export const picturesNames = Object.freeze({
  PICTURE_TYPE_POSTERS,
  PICTURE_TYPE_THUMBNAILS,
  PICTURE_TYPE_XL_POSTERS,
  PICTURE_TYPE_XL_THUMBNAILS,
  PICTURE_TYPE_BANNERS,
  PICTURE_TYPE_MOBILE_BANNERS,
  PICTURE_TYPE_HALF_BANNERS,
  PICTURE_TYPE_ROUNDEDS,
  PICTURE_TYPE_PERSONS,
  PICTURE_TYPE_AVATARS,
  PICTURE_TYPE_LOGOS,
  PICTURE_TYPE_HEADER_LOGOS,
  PICTURE_TYPE_FOOTER_LOGOS,
  PICTURE_TYPE_SQUARE_LOGOS,
  PICTURE_TYPE_FAVICONS,
  PICTURE_TYPE_PICTOGRAMS,
  PICTURE_TYPE_BACKDROPS,
  PICTURE_TYPE_CONTENT_BACKDROPS,
  PICTURE_TYPE_CONTENT_BACKDROPS_DESKTOP,
  PICTURE_TYPE_CONTENT_BACKDROPS_TABLET,
  PICTURE_TYPE_CONTENT_BACKDROPS_MOBILE,
  PICTURE_TYPE_CONTENT_BACKDROPS_ORANGE,
  PICTURE_TYPE_CONTENT_BACKDROPS_FREE,
  PICTURE_TYPE_CONTENT_BACKDROPS_APPLE_TV,
  PICTURE_TYPE_CONTENT_BACKDROPS_ANDROID_TV,
  PICTURE_TYPE_CONTENT_BACKDROPS_SAMSUNG_TV,
  PICTURE_TYPE_LG_BACKDROPS,
  PICTURE_TYPE_MD_BACKDROPS,
  PICTURE_TYPE_SM_BACKDROPS,
  PICTURE_TYPE_LG_TV_BACKDROPS,
  PICTURE_TYPE_MD_TV_BACKDROPS,
  PICTURE_TYPE_SM_TV_BACKDROPS,
  PICTURE_TYPE_LG_TABLET_BACKDROPS,
  PICTURE_TYPE_MD_TABLET_BACKDROPS,
  PICTURE_TYPE_SM_TABLET_BACKDROPS,
  PICTURE_TYPE_LANDSCAPE_MOBILE_BACKDROPS,
  PICTURE_TYPE_MD_MOBILE_BACKDROPS,
  PICTURE_TYPE_FULLSCREENS,
  PICTURE_TYPE_PORTRAIT_FULLSCREENS,
  PICTURE_TYPE_AVOD_BANNERS,
  PICTURE_TYPE_DESKTOP_AVOD_BANNERS,
  PICTURE_TYPE_TABLET_AVOD_BANNERS,
  PICTURE_TYPE_MOBILE_AVOD_BANNERS,
  PICTURE_TYPE_AVOD_TOP_BANNERS,
  PICTURE_TYPE_DESKTOP_AVOD_TOP_BANNERS,
  PICTURE_TYPE_TABLET_AVOD_TOP_BANNERS,
  PICTURE_TYPE_MOBILE_AVOD_TOP_BANNERS,
  PICTURE_TYPE_AVOD_INTERSTITIALS,
  PICTURE_TYPE_TV_AVOD_INTERSTITIALS,
  PICTURE_TYPE_MOBILE_AVOD_INTERSTITIALS,
  PICTURE_TYPE_TABLET_AVOD_INTERSTITIALS,
  PICTURE_TYPE_AVOD_BLOCKS,
  PICTURE_TYPE_TV_AVOD_BLOCKS,
  PICTURE_TYPE_DESKTOP_AVOD_BLOCKS,
  PICTURE_TYPE_TABLET_AVOD_BLOCKS,
  PICTURE_TYPE_MOBILE_AVOD_BLOCKS,
  PICTURE_TYPE_OPENGRAPH,

  // partners
  PICTURE_TYPE_BOX_ART,
  PICTURE_TYPE_SEASON_BOX_ART,
  PICTURE_TYPE_COVER_ART,
  PICTURE_TYPE_HERO_ART,
  PICTURE_TYPE_TITLE_ART,
  PICTURE_TYPE_POSTER_ART,
  PICTURE_TYPE_BETV_POSTER,
  PICTURE_TYPE_MOLOTOV_POSTER,
});

export const picturesLabels = Object.freeze({
  PICTURE_TYPE_POSTERS: PICTURE_LABEL_POSTERS,
  PICTURE_TYPE_THUMBNAILS: PICTURE_LABEL_THUMBNAILS,
  PICTURE_TYPE_XL_POSTERS: PICTURE_LABEL_XL_POSTERS,
  PICTURE_TYPE_XL_THUMBNAILS: PICTURE_LABEL_XL_THUMBNAILS,
  PICTURE_TYPE_BANNERS: PICTURE_LABEL_BANNERS,
  PICTURE_TYPE_MOBILE_BANNERS: PICTURE_LABEL_MOBILE_BANNERS,
  PICTURE_TYPE_HALF_BANNERS: PICTURE_LABEL_HALF_BANNERS,
  PICTURE_TYPE_ROUNDEDS: PICTURE_LABEL_ROUNDEDS,
  PICTURE_TYPE_PERSONS: PICTURE_LABEL_CAST,
  PICTURE_TYPE_AVATARS: PICTURE_LABEL_AVATARS,
  PICTURE_TYPE_LOGOS: PICTURE_LABEL_LOGOS,
  PICTURE_TYPE_HEADER_LOGOS: PICTURE_LABEL_LOGOS,
  PICTURE_TYPE_FOOTER_LOGOS: PICTURE_LABEL_LOGOS,
  PICTURE_TYPE_SQUARE_LOGOS: PICTURE_LABEL_LOGOS,
  PICTURE_TYPE_FAVICONS: PICTURE_LABEL_FAVICONS,
  PICTURE_TYPE_PICTOGRAMS: PICTURE_LABEL_PICTOGRAMS,
  PICTURE_TYPE_BACKDROPS: PICTURE_LABEL_BASE_BACKDROPS,
  PICTURE_TYPE_CONTENT_BACKDROPS: PICTURE_LABEL_BACKDROPS,
  PICTURE_TYPE_CONTENT_BACKDROPS_DESKTOP: PICTURE_LABEL_BACKDROPS,
  PICTURE_TYPE_CONTENT_BACKDROPS_TABLET: PICTURE_LABEL_BACKDROPS,
  PICTURE_TYPE_CONTENT_BACKDROPS_MOBILE: PICTURE_LABEL_BACKDROPS,
  PICTURE_TYPE_CONTENT_BACKDROPS_ORANGE: PICTURE_LABEL_BACKDROPS,
  PICTURE_TYPE_CONTENT_BACKDROPS_FREE: PICTURE_LABEL_BACKDROPS,
  PICTURE_TYPE_CONTENT_BACKDROPS_APPLE_TV: PICTURE_LABEL_BACKDROPS,
  PICTURE_TYPE_CONTENT_BACKDROPS_ANDROID_TV: PICTURE_LABEL_BACKDROPS,
  PICTURE_TYPE_CONTENT_BACKDROPS_SAMSUNG_TV: PICTURE_LABEL_BACKDROPS,
  PICTURE_TYPE_LG_BACKDROPS: PICTURE_LABEL_BACKDROPS,
  PICTURE_TYPE_MD_BACKDROPS: PICTURE_LABEL_BACKDROPS,
  PICTURE_TYPE_SM_BACKDROPS: PICTURE_LABEL_BACKDROPS,
  PICTURE_TYPE_LG_TV_BACKDROPS: PICTURE_LABEL_BACKDROPS,
  PICTURE_TYPE_MD_TV_BACKDROPS: PICTURE_LABEL_BACKDROPS,
  PICTURE_TYPE_SM_TV_BACKDROPS: PICTURE_LABEL_BACKDROPS,
  PICTURE_TYPE_LG_TABLET_BACKDROPS: PICTURE_LABEL_BACKDROPS,
  PICTURE_TYPE_MD_TABLET_BACKDROPS: PICTURE_LABEL_BACKDROPS,
  PICTURE_TYPE_SM_TABLET_BACKDROPS: PICTURE_LABEL_BACKDROPS,
  PICTURE_TYPE_LANDSCAPE_MOBILE_BACKDROPS: PICTURE_LABEL_BACKDROPS,
  PICTURE_TYPE_MD_MOBILE_BACKDROPS: PICTURE_LABEL_BACKDROPS,
  PICTURE_TYPE_FULLSCREENS: PICTURE_LABEL_FULLSCREENS,
  PICTURE_TYPE_PORTRAIT_FULLSCREENS: PICTURE_LABEL_PORTRAIT_FULLSCREENS,
  PICTURE_TYPE_AVOD_BANNERS,
  PICTURE_TYPE_DESKTOP_AVOD_BANNERS,
  PICTURE_TYPE_TABLET_AVOD_BANNERS,
  PICTURE_TYPE_MOBILE_AVOD_BANNERS,
  PICTURE_TYPE_AVOD_TOP_BANNERS,
  PICTURE_TYPE_DESKTOP_AVOD_TOP_BANNERS,
  PICTURE_TYPE_TABLET_AVOD_TOP_BANNERS,
  PICTURE_TYPE_MOBILE_AVOD_TOP_BANNERS,
  PICTURE_TYPE_AVOD_INTERSTITIALS,
  PICTURE_TYPE_TV_AVOD_INTERSTITIALS,
  PICTURE_TYPE_MOBILE_AVOD_INTERSTITIALS,
  PICTURE_TYPE_TABLET_AVOD_INTERSTITIALS,
  PICTURE_TYPE_AVOD_BLOCKS,
  PICTURE_TYPE_TV_AVOD_BLOCKS,
  PICTURE_TYPE_DESKTOP_AVOD_BLOCKS,
  PICTURE_TYPE_TABLET_AVOD_BLOCKS,
  PICTURE_TYPE_MOBILE_AVOD_BLOCKS,
  PICTURE_TYPE_OPENGRAPH: PICTURE_LABEL_OPENGRAPH,

  // partners
  PICTURE_LABEL_BOX_ART: PICTURE_LABEL_BOX_ART,
  PICTURE_LABEL_COVER_ART: PICTURE_LABEL_COVER_ART,
  PICTURE_LABEL_HERO_ART: PICTURE_LABEL_HERO_ART,
  PICTURE_LABEL_TITLE_ART: PICTURE_LABEL_TITLE_ART,
  PICTURE_LABEL_POSTER_ART: PICTURE_LABEL_POSTER_ART,
  PICTURE_LABEL_BETV_POSTER: PICTURE_LABEL_BETV_POSTER,
  PICTURE_LABEL_MOLOTOV_POSTER: PICTURE_LABEL_MOLOTOV_POSTER,
});

export const picturesTypes = [
  {
    id: PICTURE_TYPE_CONTENT_BACKDROPS,
    type: PICTURE_TYPE_CONTENT_BACKDROPS,
    label: PICTURE_LABEL_BACKDROPS,
    message: '',
    required: false,
    declination: false,
    maxSize: PICTURE_MAX_SIZE,
    width: 1840,
    height: 300,
    align: 'left',
    options: {
      gradient: false,
      title: false,
    },
    displayClass: 'col-span-16',
    default: new URL(
      `../images/parameters/site/default-backdrop.jpg`,
      import.meta.url
    ).href,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_CONTENT_BACKDROPS_DESKTOP,
    type: PICTURE_TYPE_CONTENT_BACKDROPS_DESKTOP,
    label: PICTURE_TYPE_CONTENT_BACKDROPS_DESKTOP,
    width: 1920,
    height: 313,
  },
  {
    id: PICTURE_TYPE_CONTENT_BACKDROPS_TABLET,
    type: PICTURE_TYPE_CONTENT_BACKDROPS_TABLET,
    label: PICTURE_TYPE_CONTENT_BACKDROPS_TABLET,
    width: 1920,
    height: 313,
  },
  {
    id: PICTURE_TYPE_CONTENT_BACKDROPS_MOBILE,
    type: PICTURE_TYPE_CONTENT_BACKDROPS_MOBILE,
    label: PICTURE_TYPE_CONTENT_BACKDROPS_MOBILE,
    width: 892,
    height: 246,
  },
  {
    id: PICTURE_TYPE_CONTENT_BACKDROPS_ORANGE,
    type: PICTURE_TYPE_CONTENT_BACKDROPS_ORANGE,
    label: PICTURE_TYPE_CONTENT_BACKDROPS_ORANGE,
    width: 1920,
    height: 313,
  },
  {
    id: PICTURE_TYPE_CONTENT_BACKDROPS_FREE,
    type: PICTURE_TYPE_CONTENT_BACKDROPS_FREE,
    label: PICTURE_TYPE_CONTENT_BACKDROPS_FREE,
    width: 1920,
    height: 313,
  },
  {
    id: PICTURE_TYPE_CONTENT_BACKDROPS_APPLE_TV,
    type: PICTURE_TYPE_CONTENT_BACKDROPS_APPLE_TV,
    label: PICTURE_TYPE_CONTENT_BACKDROPS_APPLE_TV,
    width: 1920,
    height: 313,
  },
  {
    id: PICTURE_TYPE_CONTENT_BACKDROPS_ANDROID_TV,
    type: PICTURE_TYPE_CONTENT_BACKDROPS_ANDROID_TV,
    label: PICTURE_TYPE_CONTENT_BACKDROPS_ANDROID_TV,
    width: 1920,
    height: 313,
  },
  {
    id: PICTURE_TYPE_CONTENT_BACKDROPS_SAMSUNG_TV,
    type: PICTURE_TYPE_CONTENT_BACKDROPS_SAMSUNG_TV,
    label: PICTURE_TYPE_CONTENT_BACKDROPS_SAMSUNG_TV,
    width: 1920,
    height: 313,
  },
  {
    id: PICTURE_TYPE_BACKDROPS,
    type: PICTURE_TYPE_BACKDROPS,
    label: PICTURE_LABEL_BASE_BACKDROPS,
    message: 'pictures.type.backdrop.message',
    align: 'left',
    width: 1280,
    height: 534,
    previewWidth: 426,
    previewHeight: 178,
    required: true,
    declination: false,
    maxSize: PICTURE_MAX_SIZE,
    device: 'devices.desktop',
    declinations: [
      PICTURE_TYPE_LG_BACKDROPS,
      PICTURE_TYPE_MD_BACKDROPS,
      PICTURE_TYPE_SM_BACKDROPS,
      PICTURE_TYPE_LG_TV_BACKDROPS,
      PICTURE_TYPE_MD_TV_BACKDROPS,
      PICTURE_TYPE_SM_TV_BACKDROPS,
      PICTURE_TYPE_LG_TABLET_BACKDROPS,
      PICTURE_TYPE_MD_TABLET_BACKDROPS,
      PICTURE_TYPE_SM_TABLET_BACKDROPS,
      PICTURE_TYPE_LANDSCAPE_MOBILE_BACKDROPS,
      PICTURE_TYPE_MD_MOBILE_BACKDROPS,
    ],
    options: {
      gradient: false,
      title: false,
    },
    displayClass: 'basis-[470px]',
    default: new URL(
      `../images/parameters/site/default-backdrop.jpg`,
      import.meta.url
    ).href,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_SM_BACKDROPS,
    type: PICTURE_TYPE_SM_BACKDROPS,
    label: PICTURE_LABEL_BACKDROPS,
    message: 'pictures.type.backdrop.message',
    align: 'left',
    width: 1920,
    height: 600,
    required: true,
    declination: true,
    maxSize: PICTURE_MAX_SIZE,
    device: 'devices.desktop',
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(
      `../images/parameters/site/default-sm-backdrop.jpg`,
      import.meta.url
    ).href,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_MD_BACKDROPS,
    type: PICTURE_TYPE_MD_BACKDROPS,
    label: PICTURE_LABEL_BASE_BACKDROPS,
    message: 'pictures.type.backdrop.message',
    align: 'left',
    width: 1920,
    height: 801,
    required: true,
    declination: true,
    maxSize: PICTURE_MAX_SIZE,
    device: 'devices.desktop',
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(
      `../images/parameters/site/default-backdrop.jpg`,
      import.meta.url
    ).href,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_LG_BACKDROPS,
    type: PICTURE_TYPE_LG_BACKDROPS,
    label: PICTURE_LABEL_BACKDROPS,
    message: 'pictures.type.backdrop.message',
    align: 'left',
    width: 1920,
    height: 1080,
    required: true,
    declination: true,
    maxSize: PICTURE_MAX_SIZE,
    device: 'devices.desktop',
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(
      `../images/parameters/site/default-lg-backdrop.jpg`,
      import.meta.url
    ).href,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_SM_TABLET_BACKDROPS,
    type: PICTURE_TYPE_SM_TABLET_BACKDROPS,
    label: PICTURE_LABEL_BACKDROPS,
    message: 'pictures.type.backdrop.message',
    align: 'left',
    width: 1920,
    height: 600,
    required: true,
    declination: true,
    maxSize: PICTURE_MAX_SIZE,
    device: 'devices.tablet',
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(
      `../images/parameters/site/default-sm-backdrop.jpg`,
      import.meta.url
    ).href,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_MD_TABLET_BACKDROPS,
    type: PICTURE_TYPE_MD_TABLET_BACKDROPS,
    label: PICTURE_LABEL_BACKDROPS,
    message: 'pictures.type.backdrop.message',
    align: 'left',
    width: 1920,
    height: 801,
    required: true,
    declination: true,
    maxSize: PICTURE_MAX_SIZE,
    device: 'devices.tablet',
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(
      `../images/parameters/site/default-backdrop.jpg`,
      import.meta.url
    ).href,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_LG_TABLET_BACKDROPS,
    type: PICTURE_TYPE_LG_TABLET_BACKDROPS,
    label: PICTURE_LABEL_BACKDROPS,
    message: 'pictures.type.backdrop.message',
    align: 'left',
    width: 1920,
    height: 1080,
    required: true,
    declination: true,
    maxSize: PICTURE_MAX_SIZE,
    device: 'devices.tablet',
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(
      `../images/parameters/site/default-lg-backdrop.jpg`,
      import.meta.url
    ).href,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_SM_TV_BACKDROPS,
    type: PICTURE_TYPE_SM_TV_BACKDROPS,
    label: PICTURE_LABEL_BACKDROPS,
    message: 'pictures.type.backdrop.message',
    align: 'left',
    width: 1920,
    height: 392,
    required: true,
    declination: true,
    maxSize: PICTURE_MAX_SIZE,
    device: 'devices.tv',
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(
      `../images/parameters/site/default-sm-backdrop.jpg`,
      import.meta.url
    ).href,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_MD_TV_BACKDROPS,
    type: PICTURE_TYPE_MD_TV_BACKDROPS,
    label: PICTURE_LABEL_BACKDROPS,
    message: 'pictures.type.backdrop.message',
    align: 'left',
    width: 1920,
    height: 524,
    required: true,
    declination: true,
    maxSize: PICTURE_MAX_SIZE,
    device: 'devices.tv',
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(
      `../images/parameters/site/default-backdrop.jpg`,
      import.meta.url
    ).href,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_LG_TV_BACKDROPS,
    type: PICTURE_TYPE_LG_TV_BACKDROPS,
    label: PICTURE_LABEL_BACKDROPS,
    message: 'pictures.type.backdrop.message',
    align: 'left',
    width: 1920,
    height: 1080,
    required: true,
    declination: true,
    maxSize: PICTURE_MAX_SIZE,
    device: 'devices.tv',
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(
      `../images/parameters/site/default-lg-backdrop.jpg`,
      import.meta.url
    ).href,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_LANDSCAPE_MOBILE_BACKDROPS,
    type: PICTURE_TYPE_LANDSCAPE_MOBILE_BACKDROPS,
    label: PICTURE_LABEL_BACKDROPS,
    message: 'Image mobile de la fiche de votre contenu',
    align: 'left',
    width: 720,
    height: 406,
    required: true,
    declination: true,
    maxSize: PICTURE_MAX_SIZE,
    device: 'devices.mobile',
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(
      `../images/parameters/site/default-backdrop.jpg`,
      import.meta.url
    ).href,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_MD_MOBILE_BACKDROPS,
    type: PICTURE_TYPE_MD_MOBILE_BACKDROPS,
    label: PICTURE_LABEL_BACKDROPS,
    message: 'pictures.type.backdrop.message',
    align: 'left',
    width: 720,
    height: 640,
    required: true,
    declination: true,
    maxSize: PICTURE_MAX_SIZE,
    device: 'devices.mobile',
    deviceMessage: 'devices.mobileCarousel',
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(
      `../images/parameters/site/default-backdrop.jpg`,
      import.meta.url
    ).href,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_POSTERS,
    type: PICTURE_TYPE_POSTERS,
    label: PICTURE_LABEL_POSTERS,
    message: 'pictures.type.poster.message',
    align: 'left',
    width: 1200,
    height: 1600,
    previewWidth: 160,
    previewHeight: 213,
    required: true,
    declination: false,
    maxSize: PICTURE_MAX_SIZE,
    options: {
      gradient: false,
      title: false,
    },
    displayClass: 'basis-[160px]',
    default: new URL(
      `../images/parameters/site/default-poster.jpg`,
      import.meta.url
    ).href,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_THUMBNAILS,
    type: PICTURE_TYPE_THUMBNAILS,
    label: PICTURE_LABEL_THUMBNAILS,
    message: 'pictures.type.thumbnail.message',
    align: 'left',
    width: 1920,
    height: 1080,
    previewWidth: 212,
    previewHeight: 122,
    required: false,
    maxSize: PICTURE_MAX_SIZE,
    options: {
      gradient: false,
      title: false,
    },
    displayClass: 'basis-[212px]',
    default: new URL(
      `../images/parameters/site/default-thumbnail.jpg`,
      import.meta.url
    ).href,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_BANNERS,
    type: PICTURE_TYPE_BANNERS,
    label: PICTURE_LABEL_BANNERS,
    message: 'pictures.type.banner.message',
    align: 'left',
    width: 1840,
    height: 300,
    previewWidth: 613,
    previewHeight: 100,
    required: false,
    maxSize: PICTURE_MAX_SIZE,
    declination: false,
    declinations: [PICTURE_TYPE_MOBILE_BANNERS],
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(
      `../images/parameters/site/default-banner.jpg`,
      import.meta.url
    ).href,
    displayClass: 'basis-[700px]',
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_MOBILE_BANNERS,
    type: PICTURE_TYPE_MOBILE_BANNERS,
    label: PICTURE_LABEL_MOBILE_BANNERS,
    message: 'pictures.type.mobileBanner.message',
    align: 'left',
    width: 892,
    height: 246,
    previewWidth: 892,
    previewHeight: 246,
    required: false,
    declination: true,
    maxSize: PICTURE_MAX_SIZE,
    device: 'devices.mobile',
    options: {
      gradient: false,
      title: false,
    },
    declinations: [PICTURE_TYPE_MOBILE_BANNERS],
    displayClass: 'col-span-16 md:col-span-6',
    default: new URL('../images/default-image.svg', import.meta.url).href,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_MOBILE_BANNERS,
    type: PICTURE_TYPE_MOBILE_BANNERS,
    label: PICTURE_LABEL_MOBILE_BANNERS,
    message: 'pictures.type.mobileBanner.message',
    align: 'left',
    width: 360,
    height: 100,
    previewWidth: 892,
    previewHeight: 246,
    required: false,
    declination: true,
    maxSize: PICTURE_MAX_SIZE,
    device: 'devices.mobile',
    deviceMessage: 'devices.mobileCarousel',
    options: {
      gradient: false,
      title: false,
    },
    default: new URL('../images/default-image.svg', import.meta.url).href,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_ROUNDEDS,
    type: PICTURE_TYPE_ROUNDEDS,
    label: PICTURE_LABEL_ROUNDEDS,
    message: 'pictures.type.rounded.message',
    align: 'left',
    width: 225,
    height: 225,
    rounded: true,
    realSize: false,
    previewWidth: 160,
    previewHeight: 160,
    required: false,
    maxSize: PICTURE_MAX_SIZE,
    options: {
      gradient: false,
      title: false,
    },
    displayClass: 'basis-[160px]',
    default: new URL('../images/default-image.svg', import.meta.url).href,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_PERSONS,
    type: PICTURE_TYPE_PERSONS,
    label: PICTURE_LABEL_CAST,
    width: 225,
    height: 225,
    previewWidth: 160,
    previewHeight: 160,
    rounded: true,
    maxSize: PICTURE_MAX_SIZE,
    align: 'left',
    options: {
      gradient: false,
      title: false,
    },
    displayClass: 'basis-[160px]',
    default: new URL('../images/default-avatar.png', import.meta.url).href,
    placeholder: new URL('../images/default-avatar.png', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_AVATARS,
    type: PICTURE_TYPE_AVATARS,
    label: PICTURE_LABEL_AVATARS,
    message: 'pictures.type.avatar.message',
    align: 'left',
    width: 225,
    height: 225,
    rounded: true,
    realSize: false,
    previewWidth: 160,
    previewHeight: 160,
    required: false,
    maxSize: PICTURE_MAX_SIZE,
    options: {
      gradient: false,
      title: false,
    },
    displayClass: 'basis-[160px]',
    default: new URL('../images/default-image.svg', import.meta.url).href,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_XL_POSTERS,
    type: PICTURE_TYPE_XL_POSTERS,
    label: PICTURE_LABEL_XL_POSTERS,
    message: 'pictures.type.posterXl.message',
    align: 'left',
    width: 1200,
    height: 1600,
    previewWidth: 210,
    previewHeight: 278,
    required: false,
    maxSize: PICTURE_MAX_SIZE,
    options: {
      gradient: false,
      title: false,
    },
    displayClass: 'basis-[210px]',
    default: new URL('../images/default-image.svg', import.meta.url).href,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_XL_THUMBNAILS,
    type: PICTURE_TYPE_XL_THUMBNAILS,
    label: PICTURE_LABEL_XL_THUMBNAILS,
    message: 'pictures.type.thumbnailXl.message',
    align: 'left',
    width: 1920,
    height: 1080,
    previewWidth: 320,
    previewHeight: 180,
    required: false,
    maxSize: PICTURE_MAX_SIZE,
    options: {
      gradient: false,
      title: false,
    },
    displayClass: 'basis-[320px]',
    default: new URL(
      `../images/parameters/site/default-thumbnail.jpg`,
      import.meta.url
    ).href,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_HALF_BANNERS,
    type: PICTURE_TYPE_HALF_BANNERS,
    label: PICTURE_LABEL_HALF_BANNERS,
    message: 'pictures.type.halfBanner.message',
    align: 'left',
    width: 892,
    height: 246,
    previewWidth: 432,
    previewHeight: 119,
    required: false,
    maxSize: PICTURE_MAX_SIZE,
    options: {
      gradient: false,
      title: false,
    },
    displayClass: 'basis-[340px]',
    default: new URL(
      `../images/parameters/site/default-banner.jpg`,
      import.meta.url
    ).href,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_LOGOS,
    type: PICTURE_TYPE_LOGOS,
    label: PICTURE_LABEL_LOGOS,
    realSize: true,
    width: 200,
    height: 200,
    previewWidth: 200,
    maxSize: PICTURE_MAX_SIZE,
    align: 'left',
  },
  {
    id: PICTURE_TYPE_HEADER_LOGOS,
    type: PICTURE_TYPE_HEADER_LOGOS,
    label: PICTURE_LABEL_LOGOS,
    realSize: true,
    width: 200,
    height: 200,
    previewWidth: 200,
    maxSize: PICTURE_MAX_SIZE,
    align: 'left',
  },
  {
    id: PICTURE_TYPE_FOOTER_LOGOS,
    type: PICTURE_TYPE_FOOTER_LOGOS,
    label: PICTURE_LABEL_LOGOS,
    realSize: true,
    width: 200,
    height: 200,
    previewWidth: 200,
    maxSize: PICTURE_MAX_SIZE,
    align: 'left',
  },
  {
    id: PICTURE_TYPE_SQUARE_LOGOS,
    type: PICTURE_TYPE_SQUARE_LOGOS,
    label: PICTURE_LABEL_LOGOS,
    realSize: false,
    width: 200,
    height: 200,
    previewWidth: 200,
    maxSize: PICTURE_MAX_SIZE,
    align: 'left',
  },
  {
    id: PICTURE_TYPE_FAVICONS,
    type: PICTURE_TYPE_FAVICONS,
    label: PICTURE_LABEL_FAVICONS,
    width: 96,
    height: 96,
    maxSize: PICTURE_MAX_SIZE,
    align: 'left',
  },
  {
    id: PICTURE_TYPE_PICTOGRAMS,
    type: PICTURE_TYPE_PICTOGRAMS,
    label: PICTURE_LABEL_PICTOGRAMS,
    width: 48,
    height: 48,
    maxSize: PICTURE_MAX_SIZE,
    align: 'left',
    message: 'pictures.type.pictogram.message',
  },
  {
    id: PICTURE_TYPE_OPENGRAPH,
    type: PICTURE_TYPE_OPENGRAPH,
    label: PICTURE_LABEL_OPENGRAPH,
    realSize: false,
    width: 1200,
    height: 630,
    maxSize: PICTURE_MAX_SIZE,
    previewWidth: 300,
    previewHeight: 157,
    align: 'left',
    message: 'pictures.type.opengraph.message',
    displayClass: 'col-span-6 md:col-span-4',
  },
  {
    id: PICTURE_TYPE_FULLSCREENS,
    type: PICTURE_TYPE_FULLSCREENS,
    label: PICTURE_LABEL_FULLSCREENS,
    message: '',
    croppaMessage: 'pictures.type.fullscreen.cropper',
    align: 'left',
    width: 1920,
    height: 1080,
    previewWidth: 1920 / 4,
    previewHeight: 1080 / 4,
    required: false,
    declination: false,
    maxSize: PICTURE_MAX_SIZE,
    device: 'devices.desktop',
    declinations: [PICTURE_TYPE_PORTRAIT_FULLSCREENS],
    options: {
      gradient: false,
      title: false,
    },
    displayClass: 'basis-[470px]',
    default: new URL(
      `../images/parameters/site/default-backdrop.jpg`,
      import.meta.url
    ).href,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_PORTRAIT_FULLSCREENS,
    type: PICTURE_TYPE_PORTRAIT_FULLSCREENS,
    label: PICTURE_LABEL_PORTRAIT_FULLSCREENS,
    message: 'pictures.type.portraitFullscreen.message',
    align: 'left',
    width: 1080,
    height: 1920,
    previewWidth: 1080 / 4,
    previewHeight: 1920 / 4,
    required: false,
    declination: true,
    maxSize: PICTURE_MAX_SIZE,
    device: 'devices.orientation.portrait',
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(
      `../images/parameters/site/default-backdrop.jpg`,
      import.meta.url
    ).href,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_AVOD_BANNERS,
    type: PICTURE_TYPE_AVOD_BANNERS,
    label: 'Image bannière de base',
    message: '',
    croppaMessage:
      'Attention, l’image peut être coupée selon la résolution de l’écran de l’utilisateur. Pensez à mettre les informations visuelles principales au centre de votre image.',
    align: 'left',
    width: 970,
    height: 250,
    previewWidth: 485,
    previewHeight: 125,
    required: false,
    declination: false,
    maxSize: 2000,
    device: 'Ordinateur',
    declinations: [
      PICTURE_TYPE_DESKTOP_AVOD_BANNERS,
      PICTURE_TYPE_TABLET_AVOD_BANNERS,
      PICTURE_TYPE_MOBILE_AVOD_BANNERS,
    ],
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(`../images/image-ad-placeholder.png`, import.meta.url)
      .href,
  },
  {
    id: PICTURE_TYPE_DESKTOP_AVOD_BANNERS,
    type: PICTURE_TYPE_DESKTOP_AVOD_BANNERS,
    label: '',
    message: '',
    align: 'left',
    width: 970,
    height: 250,
    required: false,
    declination: true,
    maxSize: 2000,
    device: 'Ordinateur',
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(`../images/image-ad-placeholder.png`, import.meta.url)
      .href,
  },
  {
    id: PICTURE_TYPE_TABLET_AVOD_BANNERS,
    type: PICTURE_TYPE_TABLET_AVOD_BANNERS,
    label: '',
    message: '',
    align: 'left',
    width: 772,
    height: 199,
    required: false,
    declination: true,
    maxSize: 2000,
    device: 'Tablette',
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(`../images/image-ad-placeholder.png`, import.meta.url)
      .href,
  },
  {
    id: PICTURE_TYPE_MOBILE_AVOD_BANNERS,
    type: PICTURE_TYPE_MOBILE_AVOD_BANNERS,
    label: '',
    message: '',
    align: 'left',
    width: 343,
    height: 457,
    required: false,
    declination: true,
    maxSize: 2000,
    device: 'Mobile',
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(`../images/image-ad-placeholder.png`, import.meta.url)
      .href,
  },
  {
    id: PICTURE_TYPE_AVOD_TOP_BANNERS,
    type: PICTURE_TYPE_AVOD_TOP_BANNERS,
    label: 'Image top-bannière de base',
    message: '',
    croppaMessage:
      'Attention, l’image peut être coupée selon la résolution de l’écran de l’utilisateur. Pensez à mettre les informations visuelles principales au centre de votre image.',
    align: 'left',
    width: 1280,
    height: 534,
    previewWidth: 426,
    previewHeight: 178,
    required: false,
    declination: false,
    maxSize: 2000,
    device: 'Ordinateur',
    declinations: [
      PICTURE_TYPE_DESKTOP_AVOD_TOP_BANNERS,
      PICTURE_TYPE_TABLET_AVOD_TOP_BANNERS,
      PICTURE_TYPE_MOBILE_AVOD_TOP_BANNERS,
    ],
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(`../images/image-ad-placeholder.png`, import.meta.url)
      .href,
  },
  {
    id: PICTURE_TYPE_DESKTOP_AVOD_TOP_BANNERS,
    type: PICTURE_TYPE_DESKTOP_AVOD_TOP_BANNERS,
    label: '',
    message: '',
    align: 'left',
    width: 970,
    height: 250,
    previewWidth: 485,
    previewHeight: 125,
    required: false,
    declination: true,
    maxSize: 2000,
    device: 'Ordinateur',
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(`../images/image-ad-placeholder.png`, import.meta.url)
      .href,
  },
  {
    id: PICTURE_TYPE_TABLET_AVOD_TOP_BANNERS,
    type: PICTURE_TYPE_TABLET_AVOD_TOP_BANNERS,
    label: '',
    message: '',
    align: 'left',
    width: 772,
    height: 199,
    previewWidth: 386,
    previewHeight: 100,
    required: false,
    declination: true,
    maxSize: 2000,
    device: 'Tablette',
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(`../images/image-ad-placeholder.png`, import.meta.url)
      .href,
  },
  {
    id: PICTURE_TYPE_MOBILE_AVOD_TOP_BANNERS,
    type: PICTURE_TYPE_MOBILE_AVOD_TOP_BANNERS,
    label: '',
    message: '',
    align: 'left',
    width: 375,
    height: 245,
    previewWidth: 375,
    previewHeight: 245,
    required: false,
    declination: true,
    maxSize: 2000,
    device: 'Mobile',
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(`../images/image-ad-placeholder.png`, import.meta.url)
      .href,
  },
  {
    id: PICTURE_TYPE_AVOD_INTERSTITIALS,
    type: PICTURE_TYPE_AVOD_INTERSTITIALS,
    label: 'Image interstitiel de base',
    message: '',
    croppaMessage:
      'Attention, l’image peut être coupée selon la résolution de l’écran de l’utilisateur. Pensez à mettre les informations visuelles principales au centre de votre image.',
    align: 'left',
    width: 320,
    height: 480,
    previewWidth: 320,
    previewHeight: 480,
    required: false,
    declination: false,
    maxSize: 2000,
    device: 'Ordinateur',
    declinations: [
      PICTURE_TYPE_TV_AVOD_INTERSTITIALS,
      PICTURE_TYPE_TABLET_AVOD_INTERSTITIALS,
      PICTURE_TYPE_MOBILE_AVOD_INTERSTITIALS,
    ],
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(`../images/image-ad-placeholder.png`, import.meta.url)
      .href,
  },
  {
    id: PICTURE_TYPE_TV_AVOD_INTERSTITIALS,
    type: PICTURE_TYPE_TV_AVOD_INTERSTITIALS,
    label: '',
    message: '',
    align: 'left',
    width: 1024,
    height: 768,
    required: false,
    declination: true,
    maxSize: 2000,
    device: 'TV',
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(`../images/image-ad-placeholder.png`, import.meta.url)
      .href,
  },
  {
    id: PICTURE_TYPE_TABLET_AVOD_INTERSTITIALS,
    type: PICTURE_TYPE_TABLET_AVOD_INTERSTITIALS,
    label: '',
    message: '',
    align: 'left',
    width: 1024,
    height: 768,
    required: false,
    declination: true,
    maxSize: 2000,
    device: 'Tablette',
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(`../images/image-ad-placeholder.png`, import.meta.url)
      .href,
  },
  {
    id: PICTURE_TYPE_MOBILE_AVOD_INTERSTITIALS,
    type: PICTURE_TYPE_MOBILE_AVOD_INTERSTITIALS,
    label: '',
    message: '',
    align: 'left',
    width: 320,
    height: 480,
    required: false,
    declination: true,
    maxSize: 2000,
    device: 'Mobile',
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(`../images/image-ad-placeholder.png`, import.meta.url)
      .href,
  },
  {
    id: PICTURE_TYPE_AVOD_BLOCKS,
    type: PICTURE_TYPE_AVOD_BLOCKS,
    label: 'Image interstitiel de base',
    message: '',
    croppaMessage:
      'Attention, l’image peut être coupée selon la résolution de l’écran de l’utilisateur. Pensez à mettre les informations visuelles principales au centre de votre image.',
    align: 'left',
    width: 348,
    height: 464,
    previewWidth: 348,
    previewHeight: 464,
    required: false,
    declination: false,
    maxSize: 2000,
    device: 'Ordinateur',
    declinations: [
      PICTURE_TYPE_TV_AVOD_BLOCKS,
      PICTURE_TYPE_DESKTOP_AVOD_BLOCKS,
      PICTURE_TYPE_TABLET_AVOD_BLOCKS,
      PICTURE_TYPE_MOBILE_AVOD_BLOCKS,
    ],
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(`../images/image-ad-placeholder.png`, import.meta.url)
      .href,
  },
  {
    id: PICTURE_TYPE_TV_AVOD_BLOCKS,
    type: PICTURE_TYPE_TV_AVOD_BLOCKS,
    label: '',
    message: '',
    align: 'left',
    width: 348,
    height: 464,
    required: false,
    declination: true,
    maxSize: 2000,
    device: 'TV',
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(`../images/image-ad-placeholder.png`, import.meta.url)
      .href,
  },
  {
    id: PICTURE_TYPE_DESKTOP_AVOD_BLOCKS,
    type: PICTURE_TYPE_DESKTOP_AVOD_BLOCKS,
    label: '',
    message: '',
    align: 'left',
    width: 375,
    height: 500,
    required: false,
    declination: true,
    maxSize: 2000,
    device: 'Ordinateur',
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(`../images/image-ad-placeholder.png`, import.meta.url)
      .href,
  },
  {
    id: PICTURE_TYPE_TABLET_AVOD_BLOCKS,
    type: PICTURE_TYPE_TABLET_AVOD_BLOCKS,
    label: '',
    message: '',
    align: 'left',
    width: 283,
    height: 376,
    required: false,
    declination: true,
    maxSize: 2000,
    device: 'Tablette',
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(`../images/image-ad-placeholder.png`, import.meta.url)
      .href,
  },
  {
    id: PICTURE_TYPE_MOBILE_AVOD_BLOCKS,
    type: PICTURE_TYPE_MOBILE_AVOD_BLOCKS,
    label: '',
    message: '',
    align: 'left',
    width: 343,
    height: 457,
    required: false,
    declination: true,
    maxSize: 2000,
    device: 'Mobile',
    options: {
      gradient: false,
      title: false,
    },
    default: new URL(`../images/image-ad-placeholder.png`, import.meta.url)
      .href,
  },
  // partners
  {
    id: PICTURE_TYPE_BOX_ART,
    type: PICTURE_TYPE_BOX_ART,
    label: PICTURE_LABEL_BOX_ART,
    message: 'pictures.type.boxArt.message',
    align: 'left',
    width: 1920,
    height: 2560,
    previewWidth: 160,
    previewHeight: 213,
    required: true,
    declination: false,
    maxSize: PARTNER_PICTURES_MAX_SIZE,
    extensions: /\.(jpg|JPG|jpeg)$/,
    options: {
      gradient: false,
      title: false,
    },
    default: null,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_SEASON_BOX_ART,
    type: PICTURE_TYPE_SEASON_BOX_ART,
    label: PICTURE_LABEL_SEASON_BOX_ART,
    message: 'pictures.type.seasonBoxArt.message',
    align: 'left',
    width: 2560,
    height: 1920,
    previewWidth: 213,
    previewHeight: 160,
    required: true,
    declination: false,
    maxSize: PARTNER_PICTURES_MAX_SIZE,
    extensionks: /\.(jpg|JPG|jpeg)$/,
    options: {
      gradient: false,
      title: false,
    },
    default: null,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_COVER_ART,
    type: PICTURE_TYPE_COVER_ART,
    label: PICTURE_LABEL_COVER_ART,
    message: 'pictures.type.coverArt.message',
    align: 'left',
    width: 1920,
    height: 1080,
    previewWidth: 421,
    previewHeight: 236,
    required: true,
    declination: false,
    maxSize: PARTNER_PICTURES_MAX_SIZE,
    extensions: /\.(jpg|JPG|jpeg)$/,
    options: {
      gradient: false,
      title: false,
    },
    default: null,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_HERO_ART,
    type: PICTURE_TYPE_HERO_ART,
    label: PICTURE_LABEL_HERO_ART,
    message: 'pictures.type.heroArt.message',
    align: 'left',
    width: 1920,
    height: 1080,
    previewWidth: 421,
    previewHeight: 236,
    required: true,
    declination: false,
    maxSize: PARTNER_PICTURES_MAX_SIZE,
    extensions: /\.(jpg|JPG|jpeg)$/,
    options: {
      gradient: false,
      title: false,
    },
    default: null,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_TITLE_ART,
    type: PICTURE_TYPE_TITLE_ART,
    label: PICTURE_LABEL_TITLE_ART,
    message: 'pictures.type.titleArt.message',
    align: 'left',
    width: 2200,
    height: 960,
    previewWidth: 540,
    previewHeight: 236,
    required: false,
    declination: false,
    maxSize: 1000, // 1Mo max size for title art
    extensions: /\.(png|PNG)$/,
    options: {
      gradient: false,
      title: false,
    },
    default: null,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_POSTER_ART,
    type: PICTURE_TYPE_POSTER_ART,
    label: PICTURE_LABEL_POSTER_ART,
    message: 'pictures.type.posterArt.message',
    align: 'left',
    width: 2000,
    height: 3000,
    previewWidth: 160,
    previewHeight: 240,
    required: false,
    declination: false,
    maxSize: PARTNER_PICTURES_MAX_SIZE,
    extensions: /\.(jpg|JPG|jpeg)$/,
    options: {
      gradient: false,
      title: false,
    },
    default: null,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_BETV_POSTER,
    type: PICTURE_TYPE_BETV_POSTER,
    label: PICTURE_LABEL_BETV_POSTER,
    message: 'pictures.type.betvPoster.message',
    align: 'left',
    width: 1200,
    height: 1600,
    previewWidth: 160,
    previewHeight: 213,
    required: true,
    declination: false,
    maxSize: PARTNER_PICTURES_MAX_SIZE,
    extensions: /\.(jpg|JPG|jpeg)$/,
    options: {
      gradient: false,
      title: false,
    },
    default: null,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    id: PICTURE_TYPE_MOLOTOV_POSTER,
    type: PICTURE_TYPE_MOLOTOV_POSTER,
    label: PICTURE_LABEL_MOLOTOV_POSTER,
    message: 'pictures.type.molotovPoster.message',
    align: 'left',
    width: 1000,
    height: 1314,
    previewWidth: 213,
    previewHeight: 160,
    required: true,
    declination: false,
    maxSize: PARTNER_PICTURES_MAX_SIZE,
    extensions: /\.(jpg|JPG|jpeg)$/,
    options: {
      gradient: false,
      title: false,
    },
    default: null,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  }
];

export const picturesCatalogFormats = [
  PICTURE_TYPE_POSTERS,
  PICTURE_TYPE_THUMBNAILS,
];

export const picturesDefaultOptional = [
  PICTURE_TYPE_BANNERS,
  PICTURE_TYPE_HALF_BANNERS,
  PICTURE_TYPE_ROUNDEDS,
  PICTURE_TYPE_XL_POSTERS,
  PICTURE_TYPE_XL_THUMBNAILS,
  PICTURE_TYPE_OPENGRAPH,
];

export const picturesSelectionOptional = [
  PICTURE_TYPE_POSTERS,
  PICTURE_TYPE_THUMBNAILS,
  PICTURE_TYPE_XL_POSTERS,
  PICTURE_TYPE_XL_THUMBNAILS,
  PICTURE_TYPE_ROUNDEDS,
  PICTURE_TYPE_HALF_BANNERS,
  PICTURE_TYPE_BACKDROPS,
  PICTURE_TYPE_OPENGRAPH,
];

export const picturesNotCroppableTypes = [
  PICTURE_TYPE_LOGOS,
  PICTURE_TYPE_HEADER_LOGOS,
  PICTURE_TYPE_FOOTER_LOGOS,
  PICTURE_TYPE_SQUARE_LOGOS,
  PICTURE_TYPE_FAVICONS,
];

export const picturesDefaultMandatory = [PICTURE_TYPE_BACKDROPS];

export const picturesSelectionMandatory = [PICTURE_TYPE_BANNERS];

export const picturesModelList = {
  block: [
    PICTURE_TYPE_POSTERS,
    PICTURE_TYPE_BANNERS,
    PICTURE_TYPE_MOBILE_BANNERS,
    PICTURE_TYPE_BACKDROPS,
    PICTURE_TYPE_THUMBNAILS,
    PICTURE_TYPE_ROUNDEDS,
    PICTURE_TYPE_SM_BACKDROPS,
    PICTURE_TYPE_MD_BACKDROPS,
    PICTURE_TYPE_LG_BACKDROPS,
    PICTURE_TYPE_MD_TV_BACKDROPS,
    PICTURE_TYPE_SM_TV_BACKDROPS,
    PICTURE_TYPE_LG_TV_BACKDROPS,
    PICTURE_TYPE_MD_TABLET_BACKDROPS,
    PICTURE_TYPE_SM_TABLET_BACKDROPS,
    PICTURE_TYPE_LG_TABLET_BACKDROPS,
    PICTURE_TYPE_LANDSCAPE_MOBILE_BACKDROPS,
    PICTURE_TYPE_MD_MOBILE_BACKDROPS,
    PICTURE_TYPE_FULLSCREENS,
    PICTURE_TYPE_PORTRAIT_FULLSCREENS,
    PICTURE_TYPE_AVOD_BANNERS,
    PICTURE_TYPE_DESKTOP_AVOD_BANNERS,
    PICTURE_TYPE_TABLET_AVOD_BANNERS,
    PICTURE_TYPE_MOBILE_AVOD_BANNERS,
    PICTURE_TYPE_AVOD_TOP_BANNERS,
    PICTURE_TYPE_DESKTOP_AVOD_TOP_BANNERS,
    PICTURE_TYPE_TABLET_AVOD_TOP_BANNERS,
    PICTURE_TYPE_MOBILE_AVOD_TOP_BANNERS,
    PICTURE_TYPE_AVOD_INTERSTITIALS,
    PICTURE_TYPE_TV_AVOD_INTERSTITIALS,
    PICTURE_TYPE_MOBILE_AVOD_INTERSTITIALS,
    PICTURE_TYPE_TABLET_AVOD_INTERSTITIALS,
    PICTURE_TYPE_AVOD_BLOCKS,
    PICTURE_TYPE_DESKTOP_AVOD_BLOCKS,
    PICTURE_TYPE_TABLET_AVOD_BLOCKS,
    PICTURE_TYPE_MOBILE_AVOD_BLOCKS,
  ],
  category: [
    PICTURE_TYPE_POSTERS,
    PICTURE_TYPE_BANNERS,
    PICTURE_TYPE_MOBILE_BANNERS,
    PICTURE_TYPE_BACKDROPS,
    PICTURE_TYPE_THUMBNAILS,
    PICTURE_TYPE_ROUNDEDS,
    PICTURE_TYPE_SM_BACKDROPS,
    PICTURE_TYPE_MD_BACKDROPS,
    PICTURE_TYPE_LG_BACKDROPS,
    PICTURE_TYPE_MD_TV_BACKDROPS,
    PICTURE_TYPE_SM_TV_BACKDROPS,
    PICTURE_TYPE_LG_TV_BACKDROPS,
    PICTURE_TYPE_MD_TABLET_BACKDROPS,
    PICTURE_TYPE_SM_TABLET_BACKDROPS,
    PICTURE_TYPE_LG_TABLET_BACKDROPS,
    PICTURE_TYPE_LANDSCAPE_MOBILE_BACKDROPS,
    PICTURE_TYPE_MD_MOBILE_BACKDROPS,
  ],
  country: [PICTURE_TYPE_PICTOGRAMS],
  custom: [
    PICTURE_TYPE_BANNERS,
    PICTURE_TYPE_MOBILE_BANNERS,
    PICTURE_TYPE_BACKDROPS,
    PICTURE_TYPE_ROUNDEDS,
    PICTURE_TYPE_SM_BACKDROPS,
    PICTURE_TYPE_MD_BACKDROPS,
    PICTURE_TYPE_LG_BACKDROPS,
    PICTURE_TYPE_MD_TV_BACKDROPS,
    PICTURE_TYPE_SM_TV_BACKDROPS,
    PICTURE_TYPE_LG_TV_BACKDROPS,
    PICTURE_TYPE_MD_TABLET_BACKDROPS,
    PICTURE_TYPE_SM_TABLET_BACKDROPS,
    PICTURE_TYPE_LG_TABLET_BACKDROPS,
    PICTURE_TYPE_LANDSCAPE_MOBILE_BACKDROPS,
    PICTURE_TYPE_MD_MOBILE_BACKDROPS,
  ],
  gear: [
    PICTURE_TYPE_BANNERS,
    PICTURE_TYPE_MOBILE_BANNERS,
    PICTURE_TYPE_BACKDROPS,
    PICTURE_TYPE_ROUNDEDS,
    PICTURE_TYPE_SM_BACKDROPS,
    PICTURE_TYPE_MD_BACKDROPS,
    PICTURE_TYPE_LG_BACKDROPS,
    PICTURE_TYPE_MD_TV_BACKDROPS,
    PICTURE_TYPE_SM_TV_BACKDROPS,
    PICTURE_TYPE_LG_TV_BACKDROPS,
    PICTURE_TYPE_MD_TABLET_BACKDROPS,
    PICTURE_TYPE_SM_TABLET_BACKDROPS,
    PICTURE_TYPE_LG_TABLET_BACKDROPS,
    PICTURE_TYPE_LANDSCAPE_MOBILE_BACKDROPS,
    PICTURE_TYPE_MD_MOBILE_BACKDROPS,
  ],
  item: [
    PICTURE_TYPE_LOGOS,
    PICTURE_TYPE_HEADER_LOGOS,
    PICTURE_TYPE_FOOTER_LOGOS,
    PICTURE_TYPE_POSTERS,
    PICTURE_TYPE_BANNERS,
    PICTURE_TYPE_MOBILE_BANNERS,
    PICTURE_TYPE_BACKDROPS,
    PICTURE_TYPE_THUMBNAILS,
    PICTURE_TYPE_ROUNDEDS,
    PICTURE_TYPE_XL_POSTERS,
    PICTURE_TYPE_XL_THUMBNAILS,
    PICTURE_TYPE_HALF_BANNERS,
    PICTURE_TYPE_SM_BACKDROPS,
    PICTURE_TYPE_MD_BACKDROPS,
    PICTURE_TYPE_LG_BACKDROPS,
    PICTURE_TYPE_MD_TV_BACKDROPS,
    PICTURE_TYPE_SM_TV_BACKDROPS,
    PICTURE_TYPE_LG_TV_BACKDROPS,
    PICTURE_TYPE_MD_TABLET_BACKDROPS,
    PICTURE_TYPE_SM_TABLET_BACKDROPS,
    PICTURE_TYPE_LG_TABLET_BACKDROPS,
    PICTURE_TYPE_LANDSCAPE_MOBILE_BACKDROPS,
    PICTURE_TYPE_MD_MOBILE_BACKDROPS,
    PICTURE_TYPE_FULLSCREENS,
    PICTURE_TYPE_PORTRAIT_FULLSCREENS,
    PICTURE_TYPE_AVOD_BANNERS,
    PICTURE_TYPE_DESKTOP_AVOD_BANNERS,
    PICTURE_TYPE_TABLET_AVOD_BANNERS,
    PICTURE_TYPE_MOBILE_AVOD_BANNERS,
    PICTURE_TYPE_AVOD_TOP_BANNERS,
    PICTURE_TYPE_DESKTOP_AVOD_TOP_BANNERS,
    PICTURE_TYPE_TABLET_AVOD_TOP_BANNERS,
    PICTURE_TYPE_MOBILE_AVOD_TOP_BANNERS,
    PICTURE_TYPE_AVOD_INTERSTITIALS,
    PICTURE_TYPE_TV_AVOD_INTERSTITIALS,
    PICTURE_TYPE_MOBILE_AVOD_INTERSTITIALS,
    PICTURE_TYPE_TABLET_AVOD_INTERSTITIALS,
    PICTURE_TYPE_AVOD_BLOCKS,
    PICTURE_TYPE_DESKTOP_AVOD_BLOCKS,
    PICTURE_TYPE_TABLET_AVOD_BLOCKS,
  ],
  level: [
    PICTURE_TYPE_BANNERS,
    PICTURE_TYPE_MOBILE_BANNERS,
    PICTURE_TYPE_BACKDROPS,
    PICTURE_TYPE_ROUNDEDS,
    PICTURE_TYPE_SM_BACKDROPS,
    PICTURE_TYPE_MD_BACKDROPS,
    PICTURE_TYPE_LG_BACKDROPS,
    PICTURE_TYPE_MD_TV_BACKDROPS,
    PICTURE_TYPE_SM_TV_BACKDROPS,
    PICTURE_TYPE_LG_TV_BACKDROPS,
    PICTURE_TYPE_MD_TABLET_BACKDROPS,
    PICTURE_TYPE_SM_TABLET_BACKDROPS,
    PICTURE_TYPE_LG_TABLET_BACKDROPS,
    PICTURE_TYPE_LANDSCAPE_MOBILE_BACKDROPS,
    PICTURE_TYPE_MD_MOBILE_BACKDROPS,
  ],
  participant: [PICTURE_TYPE_ROUNDEDS],
  person: [
    PICTURE_TYPE_BANNERS,
    PICTURE_TYPE_MOBILE_BANNERS,
    PICTURE_TYPE_BACKDROPS,
    PICTURE_TYPE_ROUNDEDS,
    PICTURE_TYPE_SM_BACKDROPS,
    PICTURE_TYPE_MD_BACKDROPS,
    PICTURE_TYPE_LG_BACKDROPS,
    PICTURE_TYPE_MD_TV_BACKDROPS,
    PICTURE_TYPE_SM_TV_BACKDROPS,
    PICTURE_TYPE_LG_TV_BACKDROPS,
    PICTURE_TYPE_MD_TABLET_BACKDROPS,
    PICTURE_TYPE_SM_TABLET_BACKDROPS,
    PICTURE_TYPE_LG_TABLET_BACKDROPS,
    PICTURE_TYPE_LANDSCAPE_MOBILE_BACKDROPS,
    PICTURE_TYPE_MD_MOBILE_BACKDROPS,
  ],
  plan: [PICTURE_TYPE_XL_THUMBNAILS],
  platform: [
    PICTURE_TYPE_XL_POSTERS,
    PICTURE_TYPE_XL_THUMBNAILS,
    PICTURE_TYPE_HALF_BANNERS,
    PICTURE_TYPE_LOGOS,
    PICTURE_TYPE_HEADER_LOGOS,
    PICTURE_TYPE_FOOTER_LOGOS,
    PICTURE_TYPE_SQUARE_LOGOS,
    PICTURE_TYPE_CONTENT_BACKDROPS,
    PICTURE_TYPE_CONTENT_BACKDROPS_DESKTOP,
    PICTURE_TYPE_CONTENT_BACKDROPS_TABLET,
    PICTURE_TYPE_CONTENT_BACKDROPS_MOBILE,
    PICTURE_TYPE_FAVICONS,
    PICTURE_TYPE_POSTERS,
    PICTURE_TYPE_BANNERS,
    PICTURE_TYPE_MOBILE_BANNERS,
    PICTURE_TYPE_BACKDROPS,
    PICTURE_TYPE_SM_BACKDROPS,
    PICTURE_TYPE_MD_BACKDROPS,
    PICTURE_TYPE_LG_BACKDROPS,
    PICTURE_TYPE_MD_TV_BACKDROPS,
    PICTURE_TYPE_SM_TV_BACKDROPS,
    PICTURE_TYPE_LG_TV_BACKDROPS,
    PICTURE_TYPE_MD_TABLET_BACKDROPS,
    PICTURE_TYPE_SM_TABLET_BACKDROPS,
    PICTURE_TYPE_LG_TABLET_BACKDROPS,
    PICTURE_TYPE_LANDSCAPE_MOBILE_BACKDROPS,
    PICTURE_TYPE_MD_MOBILE_BACKDROPS,
    PICTURE_TYPE_THUMBNAILS,
    PICTURE_TYPE_ROUNDEDS,
    PICTURE_TYPE_PERSONS,
    PICTURE_TYPE_OPENGRAPH,
    PICTURE_TYPE_AVOD_BANNERS,
    PICTURE_TYPE_DESKTOP_AVOD_BANNERS,
    PICTURE_TYPE_TABLET_AVOD_BANNERS,
    PICTURE_TYPE_MOBILE_AVOD_BANNERS,
    PICTURE_TYPE_AVOD_TOP_BANNERS,
    PICTURE_TYPE_DESKTOP_AVOD_TOP_BANNERS,
    PICTURE_TYPE_TABLET_AVOD_TOP_BANNERS,
    PICTURE_TYPE_MOBILE_AVOD_TOP_BANNERS,
    PICTURE_TYPE_AVOD_INTERSTITIALS,
    PICTURE_TYPE_TV_AVOD_INTERSTITIALS,
    PICTURE_TYPE_MOBILE_AVOD_INTERSTITIALS,
    PICTURE_TYPE_TABLET_AVOD_INTERSTITIALS,
    PICTURE_TYPE_AVOD_BLOCKS,
    PICTURE_TYPE_DESKTOP_AVOD_BLOCKS,
    PICTURE_TYPE_TABLET_AVOD_BLOCKS,
    PICTURE_TYPE_MOBILE_AVOD_BLOCKS,
  ],
  program: [
    PICTURE_TYPE_POSTERS,
    PICTURE_TYPE_BANNERS,
    PICTURE_TYPE_MOBILE_BANNERS,
    PICTURE_TYPE_BACKDROPS,
    PICTURE_TYPE_THUMBNAILS,
    PICTURE_TYPE_ROUNDEDS,
    PICTURE_TYPE_XL_POSTERS,
    PICTURE_TYPE_XL_THUMBNAILS,
    PICTURE_TYPE_HALF_BANNERS,
    PICTURE_TYPE_SM_BACKDROPS,
    PICTURE_TYPE_MD_BACKDROPS,
    PICTURE_TYPE_LG_BACKDROPS,
    PICTURE_TYPE_MD_TV_BACKDROPS,
    PICTURE_TYPE_SM_TV_BACKDROPS,
    PICTURE_TYPE_LG_TV_BACKDROPS,
    PICTURE_TYPE_MD_TABLET_BACKDROPS,
    PICTURE_TYPE_SM_TABLET_BACKDROPS,
    PICTURE_TYPE_LG_TABLET_BACKDROPS,
    PICTURE_TYPE_LANDSCAPE_MOBILE_BACKDROPS,
    PICTURE_TYPE_MD_MOBILE_BACKDROPS,
    PICTURE_TYPE_OPENGRAPH,
    PICTURE_TYPE_BOX_ART,
    PICTURE_TYPE_SEASON_BOX_ART,
    PICTURE_TYPE_COVER_ART,
    PICTURE_TYPE_HERO_ART,
    PICTURE_TYPE_TITLE_ART,
    PICTURE_TYPE_POSTER_ART,
    PICTURE_TYPE_BETV_POSTER,
    PICTURE_TYPE_MOLOTOV_POSTER
  ],
  rating_level: [
    PICTURE_TYPE_POSTERS,
    PICTURE_TYPE_BANNERS,
    PICTURE_TYPE_MOBILE_BANNERS,
    PICTURE_TYPE_BACKDROPS,
    PICTURE_TYPE_THUMBNAILS,
    PICTURE_TYPE_ROUNDEDS,
    PICTURE_TYPE_XL_POSTERS,
    PICTURE_TYPE_XL_THUMBNAILS,
    PICTURE_TYPE_HALF_BANNERS,
    PICTURE_TYPE_SM_BACKDROPS,
    PICTURE_TYPE_MD_BACKDROPS,
    PICTURE_TYPE_LG_BACKDROPS,
    PICTURE_TYPE_PICTOGRAMS,
    PICTURE_TYPE_OPENGRAPH,
  ],
  serie: [
    PICTURE_TYPE_XL_POSTERS,
    PICTURE_TYPE_XL_THUMBNAILS,
    PICTURE_TYPE_HALF_BANNERS,
    PICTURE_TYPE_MOBILE_BANNERS,
    PICTURE_TYPE_SM_BACKDROPS,
    PICTURE_TYPE_MD_BACKDROPS,
    PICTURE_TYPE_LG_BACKDROPS,
    PICTURE_TYPE_MD_TV_BACKDROPS,
    PICTURE_TYPE_SM_TV_BACKDROPS,
    PICTURE_TYPE_LG_TV_BACKDROPS,
    PICTURE_TYPE_MD_TABLET_BACKDROPS,
    PICTURE_TYPE_SM_TABLET_BACKDROPS,
    PICTURE_TYPE_LG_TABLET_BACKDROPS,
    PICTURE_TYPE_LANDSCAPE_MOBILE_BACKDROPS,
    PICTURE_TYPE_MD_MOBILE_BACKDROPS,
    PICTURE_TYPE_OPENGRAPH,
    PICTURE_TYPE_BOX_ART,
    PICTURE_TYPE_COVER_ART,
    PICTURE_TYPE_HERO_ART,
    PICTURE_TYPE_TITLE_ART,
    PICTURE_TYPE_POSTER_ART,
    PICTURE_TYPE_BETV_POSTER,
    PICTURE_TYPE_MOLOTOV_POSTER
  ],
  season: [
    PICTURE_TYPE_XL_POSTERS,
    PICTURE_TYPE_XL_THUMBNAILS,
    PICTURE_TYPE_HALF_BANNERS,
    PICTURE_TYPE_SM_BACKDROPS,
    PICTURE_TYPE_MD_BACKDROPS,
    PICTURE_TYPE_LG_BACKDROPS,
    PICTURE_TYPE_MD_TV_BACKDROPS,
    PICTURE_TYPE_SM_TV_BACKDROPS,
    PICTURE_TYPE_LG_TV_BACKDROPS,
    PICTURE_TYPE_MD_TABLET_BACKDROPS,
    PICTURE_TYPE_SM_TABLET_BACKDROPS,
    PICTURE_TYPE_LG_TABLET_BACKDROPS,
    PICTURE_TYPE_LANDSCAPE_MOBILE_BACKDROPS,
    PICTURE_TYPE_MD_MOBILE_BACKDROPS,
    PICTURE_TYPE_OPENGRAPH,
    PICTURE_TYPE_SEASON_BOX_ART,
    PICTURE_TYPE_COVER_ART,
    PICTURE_TYPE_HERO_ART,
    PICTURE_TYPE_TITLE_ART,
    PICTURE_TYPE_POSTER_ART,
  ],
  selection: [
    PICTURE_TYPE_POSTERS,
    PICTURE_TYPE_BANNERS,
    PICTURE_TYPE_MOBILE_BANNERS,
    PICTURE_TYPE_BACKDROPS,
    PICTURE_TYPE_THUMBNAILS,
    PICTURE_TYPE_ROUNDEDS,
    PICTURE_TYPE_XL_POSTERS,
    PICTURE_TYPE_XL_THUMBNAILS,
    PICTURE_TYPE_HALF_BANNERS,
    PICTURE_TYPE_SM_BACKDROPS,
    PICTURE_TYPE_MD_BACKDROPS,
    PICTURE_TYPE_LG_BACKDROPS,
    PICTURE_TYPE_MD_TV_BACKDROPS,
    PICTURE_TYPE_SM_TV_BACKDROPS,
    PICTURE_TYPE_LG_TV_BACKDROPS,
    PICTURE_TYPE_MD_TABLET_BACKDROPS,
    PICTURE_TYPE_SM_TABLET_BACKDROPS,
    PICTURE_TYPE_LG_TABLET_BACKDROPS,
    PICTURE_TYPE_LANDSCAPE_MOBILE_BACKDROPS,
    PICTURE_TYPE_MD_MOBILE_BACKDROPS,
    PICTURE_TYPE_OPENGRAPH,
  ],
  tag: [
    PICTURE_TYPE_BANNERS,
    PICTURE_TYPE_MOBILE_BANNERS,
    PICTURE_TYPE_BACKDROPS,
    PICTURE_TYPE_ROUNDEDS,
    PICTURE_TYPE_SM_BACKDROPS,
    PICTURE_TYPE_MD_BACKDROPS,
    PICTURE_TYPE_LG_BACKDROPS,
    PICTURE_TYPE_MD_TV_BACKDROPS,
    PICTURE_TYPE_SM_TV_BACKDROPS,
    PICTURE_TYPE_LG_TV_BACKDROPS,
    PICTURE_TYPE_MD_TABLET_BACKDROPS,
    PICTURE_TYPE_SM_TABLET_BACKDROPS,
    PICTURE_TYPE_LG_TABLET_BACKDROPS,
    PICTURE_TYPE_LANDSCAPE_MOBILE_BACKDROPS,
    PICTURE_TYPE_MD_MOBILE_BACKDROPS,
  ],
  user: [PICTURE_TYPE_ROUNDEDS],
  version: [
    PICTURE_TYPE_BACKDROPS,
    PICTURE_TYPE_ROUNDEDS,
    PICTURE_TYPE_SM_BACKDROPS,
    PICTURE_TYPE_MD_BACKDROPS,
    PICTURE_TYPE_LG_BACKDROPS,
    PICTURE_TYPE_MD_TV_BACKDROPS,
    PICTURE_TYPE_SM_TV_BACKDROPS,
    PICTURE_TYPE_LG_TV_BACKDROPS,
    PICTURE_TYPE_MD_TABLET_BACKDROPS,
    PICTURE_TYPE_SM_TABLET_BACKDROPS,
    PICTURE_TYPE_LG_TABLET_BACKDROPS,
    PICTURE_TYPE_LANDSCAPE_MOBILE_BACKDROPS,
    PICTURE_TYPE_MD_MOBILE_BACKDROPS,
  ],
  video: [PICTURE_TYPE_THUMBNAILS],
};

export const rowImageConfig = [
  {
    format: PICTURE_TYPE_BACKDROPS,
    ratio: 16 / 9,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    format: PICTURE_TYPE_SM_BACKDROPS,
    ratio: 16 / 9,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    format: PICTURE_TYPE_MD_BACKDROPS,
    ratio: 16 / 9,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    format: PICTURE_TYPE_LG_BACKDROPS,
    ratio: 16 / 9,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    format: PICTURE_TYPE_THUMBNAILS,
    ratio: 16 / 9,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    format: PICTURE_TYPE_POSTERS,
    ratio: 0.75,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    format: PICTURE_TYPE_BANNERS,
    ratio: 21 / 9,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    format: PICTURE_TYPE_MOBILE_BANNERS,
    ratio: 21 / 9,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    format: PICTURE_TYPE_ROUNDEDS,
    ratio: 1,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    format: PICTURE_TYPE_XL_POSTERS,
    ratio: 0.75,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    format: PICTURE_TYPE_XL_THUMBNAILS,
    ratio: 1.77,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
  {
    format: PICTURE_TYPE_HALF_BANNERS,
    ratio: 3.41,
    placeholder: new URL('../images/default-image.svg', import.meta.url).href,
  },
];
