export class Audio {
  constructor({
    id = null,
    url = null,
    locale = '',
    removable = false,
  } = {}) {
    this.id = id;
    this.url = url;
    this.locale = locale;
    this.removable = removable;
  }
}

export function createAudio(data) {
  return Object.freeze(new Audio(data));
}

export default Audio;
