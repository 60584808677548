export const defaultAll = { data: [], meta: { pagination: null } };

const state = {
  all: defaultAll,
  currentNewVideo: {},
  savedVideo: {},
  currentNewVideoTimecodes: [],
  savedNewVideoTimecodes: [],
  formattedVideo: {},
  loading: false,
  assetsDelta: {audios: {created: [], deleted: []}, subtitles: {created: [], deleted: []}},
};

export default state;
