import Audio from '@/models/Audio';
import Picture from '@/models/Picture';
import Subtitle from '@/models/Subtitle';

export class Video {
  constructor({
    id = 1,
    file_name = '',
    name = '',
    cover_url = '',
    audios = [],
    audiotracks = [],
    subtitles = {},
    is_free = false,
    free_video_id = null,
    created_at = new Date().toISOString(),
    upload = {
      progress: '',
      timeRemaining: '',
      etags: [],
    },
    ingest = {
      progress: '',
      timeRemaining: '',
    },
    size = null,
    type = null,
    origin_server = '',
    path = '',
    encoder_queue = null,
    chromecast = {},
    is_drmised = false,
    burned_subtitles = [],
    duration = null,
    lifespan = null,
    main_language = [],
    mediainfo = {},
    picture = {},
    streams = {},
    thumbnail_url = null,
    programs = [],
    programs_count = 0,
    group_environment = 'otto',
    target = 'default',
  }) {
    this.id = id;
    this.fileName = file_name;
    this.name = name;
    this.isFree = is_free;
    this.freeVideoId = free_video_id;
    this.coverUrl = cover_url;
    this.createdAt = created_at;
    this.upload = upload;
    this.ingest = ingest;
    this.size = size;
    this.type = type;
    this.chromecast = chromecast;
    this.isDrmised = is_drmised;
    this.origin_server = origin_server;
    this.path = path;
    this.encoder_queue = encoder_queue;
    this.burnedSubtitles = burned_subtitles;
    this.duration = duration;
    this.lifespan = lifespan;
    this.mainLanguage = main_language;
    this.mediaInfo = mediainfo;
    this.streams = streams;
    this.thumbnailUrl = thumbnail_url;
    this.programs = programs;
    this.programs_count = programs_count;
    this.group_environment = group_environment;
    this.target = group_environment === 'free' ? 'free' : 'default'; // remove this once we fully manage freevideos as a partner
    this.setAudios(audiotracks, audios);
    this.setSubtitles(subtitles);
    this.setPicture(picture);
  }

  setAudios(audiotracks, audios) {
    this.audios = [];

    audiotracks.forEach((locale) => {
      this.audios.push(new Audio({ locale: locale, removable: false }));
    });

    (audios ?? []).forEach((audio) => {
      audio.removable = true;
      this.audios.push(new Audio(audio));
    });

  }

  setSubtitles(subtitles) {
    if (Array.isArray(subtitles)) {
      this.subtitles = subtitles;
      return;
    }

    this.subtitles = [];

    for (const [format, subtitleByFormat] of Object.entries(subtitles)) {
      for (const [locale, subtitleByLocale] of Object.entries(subtitleByFormat)) {
        this.subtitles.push(new Subtitle(subtitleByLocale));
      }
    }
  }

  setPicture(picture) {
    this.picture = Picture.getDefaultPicture('video');
    const pictureTypes = Picture.getDefaultPictureTypesList('video');
    if (typeof picture === 'object') {
      pictureTypes.forEach((pictureType) => {
        this.picture[pictureType] = picture[pictureType] ?? [];
      });
    } else {
      pictureTypes.forEach((pictureType) => {
        this.picture[pictureType] = [];
      });
    }
  }
}

export default Video;
