import { GenericBillingService } from '@/services';
import { Plan } from '../../models/Plan';
import { cloneDeep } from 'lodash';
import { hasDifference } from '@/utils/diffTools';
import utils from '@/utils/utils';

const planService = new GenericBillingService('plans');

const state = {
  loading: false,
  formattedAllRank: [],

  all: { data: [], meta: { pagination: { total_pages: 0, per_page: 0 } } },
  allSaved: { data: [], meta: { pagination: { total_pages: 0, per_page: 0 } } },

  allTemporary: {
    data: [],
    meta: { pagination: { total_pages: 0, per_page: 0 } },
  },

  formattedNewPlan: null,
  currentNewPlan: new Plan({}),
  savedNewPlan: new Plan({}),
};

const getters = {
  loading: (state) => {
    return state.loading;
  },
  shouldSaveOne: (state) => {
    return hasDifference(state.currentNewPlan, state.savedNewPlan);
  },
  shouldSaveAll: (state) => {
    let currentIds = state.all.data.map((x) => x.id).join('-');
    let savedIds = state.allSaved.data.map((x) => x.id).join('-');
    return currentIds !== savedIds;
  },
  allTemporary: (state) => {
    return state.allTemporary;
  },
};

const actions = {
  search(
    { commit, dispatch },
    {
      mode = 'classic',
      page = 1,
      keyword = '',
      sort = 'id:desc',
      facets = [],
      count = 5,
    }
  ) {
    commit('searchRequest', mode);
    let queryString = 'count=' + count + '&page=' + page + '&sort=' + sort;
    if (keyword.length > 0) {
      queryString += '&query=' + keyword;
    }
    if (facets.length > 0) {
      let filters = [];
      facets.forEach((facet) => {
        if (typeof filters[facet.facet_id] === 'undefined') {
          filters[facet.facet_id] = [];
        }
        filters[facet.facet_id].push(facet.id);
      });
      Object.keys(filters).forEach(function (key) {
        queryString +=
          '&' +
          key +
          '=' +
          (filters[key].length > 1
            ? 'in:' + filters[key].join(',')
            : filters[key][0]);
      });
    }
    return planService.search('', queryString).then(
      (plans) => {
        commit('searchSuccess', { plans, mode });
        return Promise.resolve(plans);
      },
      (error) => {
        commit('searchFailure', error);
        return Promise.reject(error);
      }
    );
  },
  switchStatus({ commit, dispatch }, { planId, type }) {
    let plan = state.allSaved.data.find((x) => x.id === planId);
    commit('putRequest', { plan, type });
    return planService
      .update(state.formattedNewPlan, state.formattedNewPlan.id)
      .then(
        (plan) => {
          commit('putSuccess', plan);
          const alert = {
            id: 'plan-well-updated',
            icon: 'check',
            type: 'valid',
            message: 'notifications.update.success',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.resolve(plan);
        },
        (error) => {
          commit('getFailure', error);
          const alert = {
            id: 'plan-not-updated',
            icon: 'close',
            type: 'error',
            message: 'notifications.update.error',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(error);
        }
      );
  },
  setCurrentOfferByPlan({ commit }, plan) {
    commit('setCurrentOfferByPlan', plan);
  },
  getAll({ commit, dispatch }, query) {
    commit('getAllRequest');
    return planService.getAll(query).then(
      (response) => {
        commit('getAllSuccess', response);
        return Promise.resolve(response);
      },
      (error) => {
        commit('getAllFailure', error);
        return Promise.reject(error);
      }
    );
  },
  get({ state, commit, dispatch }, id) {
    commit('getRequest');
    return planService.get(id).then(
      (response) => {
        commit('getSuccess', response);
        return Promise.resolve(response);
      },
      (error) => {
        commit('getFailure', error);
        return Promise.reject(error);
      }
    );
  },
  store({ commit, dispatch }) {
    commit('postRequest');
    return planService.create(state.formattedNewPlan).then(
      (newPlan) => {
        commit('postSuccess', newPlan);
        const alert = {
          id: 'plans-well-created',
          icon: 'check',
          type: 'valid',
          message: 'notifications.create.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve(newPlan);
      },
      (error) => {
        commit('postFailure', error);

        let message = error?.errors?.error ?? 'notifications.create.error';
        message =
          message === 'A plan or price with this ID already exists.'
            ? 'marketing.pages.plan.formErrors.title.alreadyExists'
            : message;

        const alert = {
          id: 'plans-not-created',
          icon: 'close',
          type: 'error',
          message,
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
  update({ commit, dispatch }) {
    commit('putRequest');
    return planService
      .update(state.formattedNewPlan, state.formattedNewPlan.id)
      .then(
        (plan) => {
          commit('putSuccess', plan);
          const alert = {
            id: 'plan-well-updated',
            icon: 'check',
            type: 'valid',
            message: 'notifications.update.success',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.resolve(plan);
        },
        (error) => {
          commit('getFailure', error);
          const alert = {
            id: 'plan-not-updated',
            icon: 'close',
            type: 'error',
            message: 'notifications.update.error',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(error);
        }
      );
  },
  updateRanks({ commit, dispatch }) {
    commit('postRankRequest');
    const planRankService = new GenericBillingService('plans/bulk-update');
    return planRankService.create(state.formattedAllRank).then(
      (newPlan) => {
        commit('postRankSuccess');
        const alert = {
          id: 'plans-well-ranked',
          icon: 'check',
          type: 'valid',
          message: 'notifications.update.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve(newPlan);
      },
      (error) => {
        commit('postRankFailure', error);
        const alert = {
          id: 'plans-not-ranked',
          icon: 'close',
          type: 'error',
          message: error.error ?? 'notifications.update.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
  reset({ commit }) {
    commit('reset');
  },
};

const mutations = {
  reset(state) {
    state.formattedAllRank = [];
    state.allTemporary = {
      data: [],
      meta: { pagination: { total_pages: 0, per_page: 0 } },
    };
    state.all = {
      data: [],
      meta: { pagination: { total_pages: 0, per_page: 0 } },
    };
    state.allSaved = {
      data: [],
      meta: { pagination: { total_pages: 0, per_page: 0 } },
    };
    state.loading = false;
    state.formatted = null;
    state.currentNewPlan = new Plan({});
    state.savedNewPlan = new Plan({});
    state.formattedNewPlan = new Plan({});
  },
  resetTemporary(state) {
    state.allTemporary = {
      data: [],
      meta: { pagination: { total_pages: 0, per_page: 0 } },
    };
  },
  setLoading(state, value) {
    state.loading = value;
  },

  searchRequest(state, mode) {
    state.loading = true;
    if (mode === 'classic') {
      state.all = {
        data: [],
        meta: { pagination: { total_pages: 0, per_page: 0 } },
      };
      state.allSaved = {
        data: [],
        meta: { pagination: { total_pages: 0, per_page: 0 } },
      };
    } else if (mode === 'temporary') {
      state.allTemporary = {
        data: [],
        meta: { pagination: { total_pages: 0, per_page: 0 } },
      };
    }
  },
  searchSuccess(state, { plans, mode }) {
    let datasBrut = cloneDeep(plans?.data ?? []);
    let datasClean = [];
    datasBrut.forEach((dataBrut) => {
      datasClean.push(new Plan(dataBrut));
    });
    plans.data = datasClean;
    if (mode === 'classic') {
      state.all = plans;
      state.allSaved = cloneDeep(plans);
    } else if (mode === 'temporary') {
      state.allTemporary = plans;
    }
    state.loading = false;
  },
  searchFailure(state) {
    state.all = {
      data: [],
      meta: { pagination: { total_pages: 0, per_page: 0 } },
    };
    state.allSaved = {
      data: [],
      meta: { pagination: { total_pages: 0, per_page: 0 } },
    };
    state.loading = false;
  },

  getAllRequest(state) {
    state.all = [];
    state.loading = true;
  },
  getAllSuccess(state, items) {
    state.all = items.map((x) => new Plan(x));
    state.loading = false;
  },

  getRequest(state) {
    state.currentNewPlan = new Plan({});
    state.savedNewPlan = new Plan({});
    state.formattedNewPlan = new Plan({});
    state.loading = true;
  },
  getSuccess(state, plan) {
    state.currentNewPlan = new Plan(plan);
    state.savedNewPlan = new Plan(plan);
    state.loading = false;
  },
  // eslint-disable-next-line no-shadow
  getAllFailure(state) {
    state.currentNewPlan = new Plan({});
    state.savedNewPlan = new Plan({});
    state.loading = false;
  },
  getFailure(state, plan) {
    state.currentNewPlan = new Plan({});
    state.savedNewPlan = new Plan({});
    state.loading = false;
  },

  putRequest(state) {
    state.loading = true;
    let formatted = {};
    let temp = cloneDeep(state.currentNewPlan);

    formatted.id = temp.id;
    formatted.group_plan_id = temp.group_plan_id ?? 0;
    formatted.plan_code = utils.toSnake(utils.toSlug(temp.name));
    formatted.plan_name = temp.name;
    formatted.plan_title = temp.title;
    formatted.amount = temp.price ?? 0; // in cents
    formatted.amount_android = temp.price_android ?? 0; // in cents
    formatted.amount_apple = temp.price_apple ?? 0; // in cents
    formatted.currency_code = temp.currency_code;
    formatted.type = temp.type ?? 'prepaid';
    formatted.rank = temp.rank ?? 0;
    formatted.duration = 1;
    formatted.trial_period_days = temp.trial_period_days ?? 0;
    formatted.recurrent = temp.recurrent;
    formatted.arguments = temp.arguments;
    formatted.cycle_count = temp.cycle_count;
    formatted.amount_discount = temp.amount_discount;
    formatted.subscription_active = temp.active;
    formatted.description = temp.description;
    formatted.description_confirmation = temp.description_confirmation;
    formatted.description_promotion = temp.description_promotion;
    formatted.template_post_subscription = temp.template_post_subscription;
    formatted.template_home = temp.template_home;
    formatted.amount_discount = temp.orange_reduction_amount;
    formatted.cycle_count = temp.orange_reduction_cycle_count;
  
    if (formatted.type === 'orange') {
      formatted.amount_discount = temp.orange_reduction_amount * 100;
      formatted.cycle_count = temp.orange_reduction_cycle_count;
      formatted.wha_code = temp.wha_code ?? 'WHA CODE';
    }

    if (temp.interval === 'trimester') {
      formatted.interval = 'month';
      formatted.interval_count = 3;
    } else if (temp.interval === 'semester') {
      formatted.interval = 'month';
      formatted.interval_count = 6;
    } else {
      formatted.interval = temp.interval ?? 'month';
      formatted.interval_count = temp.interval_count ?? 1;
    }

    formatted.picture = temp.picture;
    // Remove base64 url data
    if (formatted.picture !== null) {
      for (const [key, value] of Object.entries(formatted.picture)) {
        let count = formatted.picture[key].length;
        for (let i = 0; i < count; i++) {
          delete formatted.picture[key][0].url;
        }
      }
    }

    state.formattedNewPlan = formatted;
  },
  putSuccess(state, plan) {
    state.formattedNewPlan = null;
    state.currentNewPlan = new Plan(plan);
    state.savedNewPlan = new Plan(plan);

    let tempAll = cloneDeep(state.all.data ?? []);
    let index = tempAll.findIndex((x) => x.id === plan.id);
    if (index >= 0) {
      tempAll[index] = new Plan(plan);
      state.all.data = tempAll;
      state.allSaved.data = cloneDeep(tempAll);
    }

    state.loading = false;
  },
  putFailure(state) {
    state.formattedNewPlan = null;
    state.loading = false;
  },

  postRequest(state) {
    state.loading = true;
    const temp = cloneDeep(state.currentNewPlan);
    let formatted = {};

    formatted.group_plan_id = temp.group_plan_id ?? 0;
    formatted.plan_code = utils.toSnake(utils.toSlug(temp.name));
    formatted.plan_name = temp.name;
    formatted.plan_title = temp.title;
    formatted.amount = temp.price ?? 0; // in cents
    formatted.amount_android = temp.price_android ?? 0; // in cents
    formatted.amount_apple = temp.price_apple ?? 0; // in cents
    formatted.currency_code = temp.currency_code;
    formatted.type = temp.type ?? 'prepaid';
    formatted.rank = temp.rank ?? 0;
    formatted.duration = 1;
    formatted.trial_period_days = temp.trial_period_days ?? 0;
    formatted.recurrent = temp.recurrent;
    formatted.arguments = temp.arguments;
    formatted.amount_discount = temp.amount_discount;
    formatted.subscription_active = temp.active;
    formatted.description = temp.description;
    formatted.description_confirmation = temp.description_confirmation;
    formatted.description_promotion = temp.description_promotion;
    formatted.template_post_subscription = temp.template_post_subscription;
    formatted.template_home = temp.template_home;
    formatted.front_display = temp.front_display;
    if (formatted.type === 'orange') {
      formatted.amount_discount = temp.orange_reduction_amount * 100;
      formatted.cycle_count = temp.orange_reduction_cycle_count;
      formatted.wha_code = temp.wha_code ?? 'WHA CODE';
    }

    if (temp.interval === 'trimester') {
      formatted.interval = 'month';
      formatted.interval_count = temp?.interval_count
        ? temp.interval_count * 3
        : 3;
    } else if (temp.interval === 'semester') {
      formatted.interval = 'month';
      formatted.interval_count = temp?.interval_count
        ? temp.interval_count * 6
        : 6;
    } else {
      formatted.interval = temp.interval ?? 'month';
      formatted.interval_count = temp.interval_count ?? 1;
    }

    formatted.picture = temp.picture;
    // Remove base64 url data
    if (formatted.picture !== null) {
      for (const [key, value] of Object.entries(formatted.picture)) {
        let count = formatted.picture[key].length;
        for (let i = 0; i < count; i++) {
          delete formatted.picture[key][0].url;
        }
      }
    }

    state.formattedNewPlan = formatted;
  },
  postSuccess(state, item) {
    state.currentNewPlan = new Plan(item);
    state.savedNewPlan = new Plan(item);
    state.formatted = null;
    state.loading = false;
  },
  postFailure(state) {
    state.all = [];
    state.loading = false;
  },

  setCurrentOfferById(state, planId) {
    let plans = cloneDeep(state.all.data);
    let plan = plans.find((x) => x.id === planId);
    state.currentNewPlan = plan;
    state.savedNewPlan = cloneDeep(plan);
  },
  setCurrentOfferByPlan(state, plan) {
    state.currentNewPlan = cloneDeep(plan);
    state.savedNewPlan = cloneDeep(plan);
  },

  postRankRequest(state) {
    state.formattedAllRank = [];
    let currents = cloneDeep(state.all.data);
    let saveds = cloneDeep(state.allSaved.data);
    let rank = 1;
    currents.forEach((current) => {
      state.formattedAllRank.push({
        front_display: true,
        rank: rank,
        id: current.id,
        plan_id: current.id,
      });
      rank++;
    });
    saveds.forEach((saved) => {
      let index = currents.findIndex((x) => x.id === saved.id);
      if (index < 0) {
        state.formattedAllRank.push({
          front_display: false,
          rank: 999,
          id: saved.id,
          plan_id: saved.id,
        });
      }
    });
  },
  postRankSuccess(state, item) {
    state.allSaved = cloneDeep(state.all);
    state.formattedAllRank = [];
    state.loading = false;
  },
  postRankFailure(state) {
    state.loading = false;
  },

  syncRank(state) {
    const plans = cloneDeep(state.all.data);
    for (let i = 0; i < plans.length; i++) {
      const plan = plans[i];
      plan.rank = i;
      state.all.data.splice(i, 1, plan);
    }
  },
  updateIndex(state, { item, oldIndex, newIndex }) {
    const plans = state.all.data;
    plans.splice(oldIndex, 1);
    if (newIndex > plans.length) {
      plans.push(item);
    } else {
      plans.splice(newIndex - 1, 0, item);
    }
  },

  add(state, newPlans) {
    const plans = cloneDeep(state.all.data);

    newPlans.forEach(function (newPlan) {
      let index = plans.findIndex((x) => x.id === newPlan.id);
      if (index < 0) {
        plans.push(newPlan);
      }
    });

    state.all.data = plans;
  },
  remove(state, id) {
    const plans = cloneDeep(state.all.data);
    state.all.data = plans.filter((x) => x.id !== id);
  },
};

export const plans = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
