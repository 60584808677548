import {
  GenericMediaService,
  GenericContentService,
  liveService,
  programService,
  seriesService,
} from '@/services';
import { cloneDeep, includes, isEmpty, isNil, isNumber } from 'lodash';
import { getField, updateField } from 'vuex-map-fields';
import { picturesNames, picturesTypes } from '@/data/pictures';
import { v4 as uuidv4 } from 'uuid';
import subtitleTypes from '@/data/subtitleTypes';

import Movie from '@/models/Movie';
import { ProgramTransformer } from '@/transformers';
import { PartnersTransformer } from '@/transformers';

import Timecode from '@/models/Timecode';
import { difference } from '@/utils/diffTools';
import moment from 'moment';
import utils from '@/utils/utils.js';

const genericContentService = new GenericContentService('programs');

const state = () => ({
  loading: true,
  loadingSerie: false,
  loadingSeason: false,
  loadingEpisode: false,
  loadingSubtitle: false,
  loadingAmazon: false,
  pendingPictureUpdates: [],
  currentProgram: {},
  status: {},
  refreshDateKey: Math.random(),

  all: {
    data: [],
    meta: {
      pagination: {
        total_pages: 0,
        per_page: 0,
        total: 0,
        count: 0,
        current_page: 1,
      },
    },
  },
  currentMoviePublicationData: {
    country_id: 250,
    publish_at: '',
    unpublish_at: '',
    publish_at_utc: '',
    unpublish_at_utc: '',
    rights_starts_at: '',
    rights_ends_at: '',
    is_free: 0,
  },

  formatedCurrentNewMovie: {},
  currentNewMovie: {},
  savedNewMovie: {},

  liveGlobalConfig: {},
  pages: {},
  rentalsAreValid: true,
  countryCode: null,
  countryId: null,
  currentPlatform: null,
  linked: {},
  videoAssetsDelta: {audios: {created: [], deleted: []}, subtitles: {created: [], deleted: []}},
});

const getters = {
  getField,
  metadata: (state, getters, rootState, rootGetters) => {
    let retval = rootState.metadataDetails.all;
    return retval;
  },
  loadingAll: (state) => {
    var loadingAll =
      state.loading ||
      state.loadingSerie ||
      state.loadingSeason ||
      state.loadingEpisode ||
      state.loadingSubtitle;
    return loadingAll;
  },
  loadingAmazon: () => {
    return state.loadingAmazon;
  },
  pictureTypesMandatory: (state, getters, rootState, rootGetters) => {
    return state.currentNewMovie?.type === 'episode'
      ? [picturesNames.PICTURE_TYPE_THUMBNAILS]
      : rootGetters['platforms/pictureTypesMandatory'];
  },
  pictureTypesOptional: (state, getters, rootState, rootGetters) => {
    const pictureTypesOptional = rootGetters['platforms/pictureTypesOptional'];
    return state.currentNewMovie?.type === 'episode'
      ? [
        picturesNames.PICTURE_TYPE_POSTERS,
        picturesNames.PICTURE_TYPE_BACKDROPS,
        ...pictureTypesOptional,
      ].filter((type) => type !== picturesNames.PICTURE_TYPE_THUMBNAILS)
      : pictureTypesOptional;
  },

  pictureTypesPartner: (state, getters, rootState, rootGetters) => {
    const feature = rootState.platforms.currentPlatform?.content_config?.feature;
    if (
      !rootGetters['platforms/hasAtleastOnePartnerEnabled'] ||
      ['live', 'collection'].includes(state?.currentNewMovie.type) ||
      (!['serie', 'program'].includes(state?.currentNewMovie.type) &&
        !feature?.environment_amazon) 
    ) {
      return [];
    }
    let pictureTypesPartner = rootGetters['platforms/pictureTypesPartner'];
    
    if (state?.currentNewMovie.type !== 'program') {
      pictureTypesPartner = pictureTypesPartner.filter((type) =>
        ![
          picturesNames.PICTURE_TYPE_BETV_POSTER,
          picturesNames.PICTURE_TYPE_MOLOTOV_POSTER
        ].includes(type)
      );
    } else {
      if (!feature?.environment_betv) {
        pictureTypesPartner = pictureTypesPartner.filter((type) =>
          type !== picturesNames.PICTURE_TYPE_BETV_POSTER
        );
      }
      if (!feature?.environment_molotov) {
        pictureTypesPartner = pictureTypesPartner.filter((type) =>
          type !== picturesNames.PICTURE_TYPE_MOLOTOV_POSTER
        );
      }
    }
    if (!feature?.environment_amazon) {
      pictureTypesPartner = pictureTypesPartner.filter((type) =>
        ![
          picturesNames.PICTURE_TYPE_TITLE_ART,
          picturesNames.PICTURE_TYPE_POSTER_ART,
          picturesNames.PICTURE_TYPE_BOX_ART,
          picturesNames.PICTURE_TYPE_SEASON_BOX_ART,
        ].includes(type)
      );
    }
    
    switch (state?.currentNewMovie.type) {
      case 'season':
        return pictureTypesPartner.filter((type) =>
          type !== picturesNames.PICTURE_TYPE_BOX_ART
        );
      case 'serie':
        return pictureTypesPartner.filter((type) =>
          type !== picturesNames.PICTURE_TYPE_BOX_ART
        );
      case 'episode':
        return [picturesNames.PICTURE_TYPE_COVER_ART];
      default:
        return pictureTypesPartner.filter((type) =>
          type !== picturesNames.PICTURE_TYPE_SEASON_BOX_ART
        );
    }
  },

  /**
   * Check if program can be saved
   * No need to compare status value. Status value are not set by user.
   *
   * @since 2020-04-03 replace movieSaved (function from tabinfo.vue) [JJ]
   * @todo refactor difference function
   */
  retDiffSaved: (state, getters, rootState, rootGetters) => {
    return difference(state.savedNewMovie, state.currentNewMovie);
  },
  retDiffCurrent: (state, getters, rootState, rootGetters) => {
    return difference(state.currentNewMovie, state.savedNewMovie);
  },
  // TODO : refactor this function
  hasNoRetDiff: (state, getters, rootState, rootGetters) => {
    const hasRetDiff = (retDiff) => {
      delete retDiff.status;
      for (const [key, value] of Object.entries(retDiff)) {
        if (key === 'publication') {;
          if ('is_free' in value?.[state.countryCode]) {
            continue;
          } else if (!getters.shouldSavedPublication) {
            delete retDiff[key];
          } 
        } else if (!isNil(value) && value.length === 0) {
          delete retDiff[key];
        }
      }
      return !(isNil(retDiff) || isEmpty(retDiff));
    };

    return (
      !hasRetDiff(getters.retDiffSaved) && !hasRetDiff(getters.retDiffCurrent)
    );    
  },
  // TODO : refactor this function
  shouldSaved: (state, getters, rootState, rootGetters) => {
    // Validation
    // - Publication info
    // - Rentals info if Tvod enabled
    if (
      !getters.canSavePublication ||
      (rootGetters['tvodRentals/shouldSaveAll'] && !rootGetters['tvodRentals/couldSaveAll']) || 
      (!getters.liveConfigIsDisabled && getters.isLiveConfigInvalid) ||
      (getters.hasNoRetDiff && !rootGetters['tvodRentals/shouldSaveAll']) ||
      getters.shouldSendToPartners
    ) {
      return false;
    }
    return true;
  },
  allData: (state) => {
    return state.all;
  },
  liveConfigIsDisabled(state) {
    const live = state.currentNewMovie.liveConfig;
    if (typeof live === 'undefined') {
      return false;
    }
    if (typeof live.newLive === 'undefined') {
      //live as been created, you cannot configure it anymore (temporary rule ?)
      return true;
    }
    const liveCreateIsInProgressStatus =
      state.currentNewMovie.live?.cloud_formation_stack_status ===
      'CREATE_IN_PROGRESS';
    const liveConfigStartAt = new Date(
      new Date(live.start_at).getTime() +
      new Date(live.start_at).getTimezoneOffset() * 60000
    );

    const offset =
      state.liveGlobalConfig?.duration_in_minutes_for_stack_creation ?? 60;
    const liveConfigModificationDateLimit = moment(liveConfigStartAt)
      .subtract(offset, 'm')
      .toDate();
    const liveConfigModificationDateLimitIsPassed = moment(
      liveConfigModificationDateLimit
    ).isBefore(getters.dateNow, 'minute');
    return (
      liveCreateIsInProgressStatus ||
      (liveConfigModificationDateLimitIsPassed && !live.newLive)
    );
  },
  canSavePublication: (state, getters) => {
    if (!state.currentNewMovie.publication) {
      return false;
    }

    if (!state.currentPlatform) {
      return false;
    }

    const publication = state.currentNewMovie.publication[state.countryCode];
    // Check difference
    const diff = difference(
      publication,
      state.savedNewMovie.publication[state.countryCode]
    );
    const noDiff = isNil(diff) || isEmpty(diff);
    if (noDiff) {
      return true;
    }

    return true;
  },
  
  shouldSavedPublication(state, getters) {
    const currentPublishAtUtc = state.currentNewMovie?.publication?.[state.countryCode]?.publish_at_utc;  
    const savedPublishAtUtc = state.savedNewMovie?.publication?.[state.countryCode]?.publish_at_utc;
    const currentUnpublishAtUtc = state.currentNewMovie?.publication?.[state.countryCode]?.unpublish_at_utc;
    const savedUnpublishAtUtc = state.savedNewMovie?.publication?.[state.countryCode]?.unpublish_at_utc;
    return (
      currentPublishAtUtc !== savedPublishAtUtc ||
      currentUnpublishAtUtc !== savedUnpublishAtUtc
    );
  },

  shouldSendToPartners(state, getters) {
    const diff = difference(
      state.currentNewMovie.partnersPackages, 
      state.savedNewMovie.partnersPackages
    );
    return !isNil(diff) && !isEmpty(diff);
  },
  isProgramTypeLive: (state) => {
    return state.currentNewMovie.program_type_id === 5;
  },
  dateNow: (state) => {
    return state.refreshDateKey > 0 && moment();
  },

  minLiveStartDate: (state, getters) => {
    return cloneDeep(getters.dateNow).add(2, 'hours');
  },
  minLiveEndDate: (state) => {
    if (!getters.isProgramTypeLive) {
      return null;
    }
    const live = state.currentNewMovie.liveConfig;
    if (isNil(live.start_at)) {
      return null;
    }
    return moment(live.start_at).add(5, 'minutes');
  },
  isLiveStartDateIsValid: (state, getters) => {
    if (!getters.isProgramTypeLive) {
      return true;
    }
    const live = state.currentNewMovie.liveConfig;
    if (isNil(live.start_at)) { // date is considered valid if it is not defined, you still can save program even if it cannot create live media
      return true;
    }
    const startDate = moment(live.start_at);

    return (
      !startDate.isBefore(getters.minLiveStartDate, 'm')
    );
  },
  isLiveEndDateIsValid: (state, getters) => {
    if (!getters.isProgramTypeLive) {
      return true;
    }
    const live = state.currentNewMovie.liveConfig;
    if (isNil(live.end_at)) { // date is considered valid if it is not defined, you still can save program even if it cannot create live media
      return true;
    }

    return !moment(live.end_at).isBefore(getters.minLiveEndDate, 'm');
  },
  isLiveStartDateIsDefinedButNotEndDate: (state, getters) => {
    if (!getters.isProgramTypeLive) {
      return false;
    }
    const live = state.currentNewMovie.liveConfig;
    return !isNil(live.start_at) && isNil(live.end_at);
  },
  isLiveEndDateIsDefinedButNotStartDate: (state, getters) => {
    if (!getters.isProgramTypeLive) {
      return false;
    }
    const live = state.currentNewMovie.liveConfig;
    return !isNil(live.end_at) && isNil(live.start_at);
  },
  isLiveConfigInvalid: (state, getters) => {
    if (!getters.isProgramTypeLive) {
      return false;
    }
    return (
      !getters.isLiveStartDateIsValid ||
      !getters.isLiveEndDateIsValid ||
      getters.isLiveStartDateIsDefinedButNotEndDate ||
      getters.isLiveEndDateIsDefinedButNotStartDate
    );
  },
  canSaveLive: (state, getters) => {
    if (!getters.isProgramTypeLive) {
      return true;
    }
    const live = state.currentNewMovie.liveConfig;

    // Check RTMP_PULL Url validity
    /*
    const rtmp_pull_url_regex = /^(?:rtmp(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g
    const isRtmpPull = live.input_type === 'RTMP_PULL'
    const validRtmpUrl = rtmp_pull_url_regex.test(live.pri_pull_url)
    const invalidUrl = isRtmpPull && !validRtmpUrl
     */

    // Check dates validity
    const datesAreEmpty =
      live.start_at === null ||
      live.end_at === null ||
      live.start_at === '' ||
      live.end_at === '';
    const startDateIsInThePast =
      live.start_at && state.currentNewMovie.status !== 'online'
        ? moment(live.start_at).isBefore(
          cloneDeep(getters.dateNow).add(2, 'hours'),
          'minute'
        )
        : null;
    const endDateIsInThePast = live.end_at
      ? moment(live.end_at).isBefore(getters.dateNow, 'minute')
      : null;
    const endDateIsBeforeStartDate =
      live.start_at && live.end_at
        ? moment(live.end_at).isBefore(moment(live.start_at), 'minute')
        : null;

    if (
      datesAreEmpty ||
      startDateIsInThePast ||
      endDateIsInThePast ||
      endDateIsBeforeStartDate
    ) {
      return false;
    }

    return true;
  },
  isFree: (state, getters) => {
    return state.currentNewMovie?.publication?.[state.countryCode]?.is_free;
  }
};

const actions = {
  getAll({ commit }, queryString) {
    commit('setLoadingDynamic', { type: 'program', value: true });
    return programService.getAll(queryString).then(
      (programs) => {
        commit('getAllSuccess', programs);
        commit('setLoadingDynamic', { type: 'program', value: false });
        return Promise.resolve(programs);
      },
      (error) => {
        commit('cleanProgramStates', error);
        commit('setLoadingDynamic', { type: 'program', value: false });
      }
    );
  },
  get({ commit, dispatch, rootState, rootGetters }, id) {
    commit('setLoadingDynamic', { type: 'program', value: true });
    return programService.get(id).then(
      async (program) => {
        await commit('storeCountryInfos', rootState.platforms.currentPlatform);
        commit('getSuccess', program);
        if (program.type === 'live') {
          await dispatch('getLiveConfig');
        }
        if (program?.id && program?.type) {
          if (
            ['serie', 'season', 'collection'].includes(program.type)
          ) {
            await dispatch('getStatus', program.id);
          } else if (['episode'].includes(program.type)) {
            const id = program?.season_program_id ?? program?.serie_program_id
            if (id) {
              await dispatch('getStatus', id);
            }
          }
        }
        commit('setLoadingDynamic', { type: 'program', value: false });
        return Promise.resolve(program);
      },
      (error) => {
        commit('cleanProgramStates', error);
        commit('setLoadingDynamic', { type: 'program', value: false });
        return Promise.reject();
      }
    );
  },
  setSerieSeasons({ commit, dispatch }, response) {
    commit('setSerieSeasons', response.seasons);
    if (response.seasons.length === 0) {
      commit('setLoadingDynamic', { type: 'episode', value: true });
      return dispatch(
        'episodes/getAll',
        {
          serieId: response.serieId,
          page: 1,
          parent: { type: 'serie', id: response.serieId },
        },
        { root: true }
      );
    }
  },
  showReloadModal({ commit }) {
    commit(
      'showModal',
      {
        componentName: 'ModalReloadPage',
        size: 'sm',
        closeOnClickOutside: false,
        showCloseButton: false,
        componentParams: {
          message: 'notifications.update.success',
          btnLabel: 'button.refresh',
        },
      },
      { root: true }
    );
  },
  syncToAmazon({ commit, dispatch }, data) {
    commit('toggleLoadingAmazon', true);
    return programService.syncToAmazon(data).then(
      () => {
        commit('toggleLoadingAmazon', false);
        const alert = {
          id: 'movie-synced-to-amazon',
          icon: 'check',
          type: 'valid',
          message: 'notifications.update.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (error) => {
        commit('toggleLoadingAmazon', false);
        const alert = {
          id: 'movie-synced-to-amazon',
          icon: 'close',
          type: 'error',
          message: 'notifications.update.eror',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
  create({ commit, dispatch }, program) {
    commit('cleanProgramStates');
    commit('setLoadingDynamic', { type: 'program', value: true });

    if (program.program_type_id == 2) {
      return seriesService.create(program).then(
        (serie) => {
          return programService.get(serie.program_id).then(
            (programSerie) => {
              commit('setLoadingDynamic', { type: 'program', value: false });
              return Promise.resolve(programSerie);
            },
            (error) => {
              const alert = {
                id: 'movie-saved',
                icon: 'close',
                type: 'error',
                message: 'notifications.create.error',
              };
              commit('setLoadingDynamic', { type: 'program', value: false });
              dispatch('displayAlert', alert, { root: true });
              return Promise.reject(error);
            }
          );
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    } else {
      return programService.create(program).then(
        (programs) => {
          commit('setLoadingDynamic', { type: 'program', value: false });
          return Promise.resolve(programs);
        },
        (error) => {
          const alert = {
            id: 'movie-saved',
            icon: 'close',
            type: 'error',
            message: 'notifications.create.success',
          };
          commit('setLoadingDynamic', { type: 'program', value: false });
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(error);
        }
      );
    }
  },
  async update({ state, commit, getters, dispatch, rootState }) {
    if (state.loading === false) {
      commit('setLoadingDynamic', { type: 'program', value: true });
      commit('formatProgramData', [
        getters.metadata,
        rootState.platforms.currentPlatform,
      ]);
      let parent = null;
      if (state.currentNewMovie?.parent) {
        parent = state.currentNewMovie.parent;
      }
      let promises = [];
      if (state.currentNewMovie.program_type_id === 5) {
        if (
          (state.currentNewMovie.liveConfig.newLive && getters.canSaveLive) ||
          (state.currentNewMovie.live &&
            !getters.liveConfigIsDisabled &&
            getters.canSaveLive)
        ) {
          state.currentNewMovie.liveConfig.user_id =
            state.currentNewMovie.live.user_id = rootState.auth.authUser.id;
          await dispatch('updateLive');
        }
      }
      await dispatch('updateVideoAssets').catch((e) => {
        const alert = {
          id: 'movie-saved',
          icon: 'close',
          type: 'error',
          message: 'notifications.update.error',
        };
        dispatch('displayAlert', alert, { root: true });
        commit('setLoadingDynamic', { type: 'program', value: false });
        return Promise.reject(e);
      });
      await dispatch('tvodRentals/saveAll', null, { root: true }).catch((e) => {
        console.error(e);
        const alert = {
          id: 'movie-saved',
          icon: 'close',
          type: 'error',
          message: 'notifications.update.error',
        };
        dispatch('displayAlert', alert, { root: true });
        commit('setLoadingDynamic', { type: 'program', value: false });
        return Promise.reject(e);
      });
      promises.push(
        programService.update(
          state.formatedCurrentNewMovie,
          state.formatedCurrentNewMovie.id
        )
      );
      return Promise.all(promises)
        .then(
          async (program) => {
            var promises = cloneDeep(program);
            var programToCommit = promises.pop();

            programToCommit.parent = parent;
            commit('updateSuccess');
            const alert = {
              id: 'movie-saved',
              icon: 'check',
              type: 'valid',
              message: 'notifications.update.success',
            };
            dispatch('displayAlert', alert, { root: true });

            commit('setLoadingDynamic', { type: 'program', value: false });
            return Promise.resolve();
          },
          (error) => {
            const alert = {
              id: 'movie-saved',
              icon: 'close',
              type: 'error',
              message: 'notifications.update.error',
            };
            dispatch('displayAlert', alert, { root: true });
            commit('setLoadingDynamic', { type: 'program', value: false });
            return Promise.reject(error);
          }
        ).catch((e) => {
          return Promise.reject(e);
        });
    }
  },
  updateRank({ commit, dispatch }, payload) {
    return programService.updateRank(payload).then(
      (program) => {
        return Promise.resolve(program);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  getLiveConfig({ commit }) {
    return liveService.getConfig().then(
      (config) => {
        commit('getLiveConfigSuccess', config);
        return Promise.resolve(config);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  getStatus({ commit }, id) {
    commit('setLoadingDynamic', { type: 'program', value: true });
    return programService.getStatus(id).then(
      async (status) => {
        commit('setStatus', status);
        commit('setLoadingDynamic', { type: 'program', value: false });
        return Promise.resolve(status);
      },
      (_) => {
        commit('setLoadingDynamic', { type: 'program', value: false });
        return Promise.reject();
      }
    );
  },

  updateLive({ state, commit, dispatch }) {
    commit('setLoadingDynamic', { type: 'live', value: true });
    const liveConfig = state.currentNewMovie.liveConfig;
    const promise = liveConfig.newLive
      ? liveService.create(liveConfig)
      : liveService.update(liveConfig);

    return promise.then(
      (live) => {
        commit('updateLiveSuccess', live.id);
        commit('setLoadingDynamic', { type: 'live', value: false });
        return Promise.resolve(live);
      },
      (error) => {
        const alert = {
          id: 'lives-saved',
          icon: 'close',
          type: 'error',
          message: 'notifications.update.error',
        };
        dispatch('displayAlert', alert, { root: true });
        commit('setLoadingDynamic', { type: 'live', value: false });
        return Promise.reject(error);
      }
    );
  },

  getTimecodes({ commit, dispatch }, id) {
    commit('setLoading', true);
    return programService.getTimecodes(id).then(
      (timecodes) => {
        commit('getTimecodesSuccess', { id, timecodes });
        commit('setLoading', false);
        return Promise.resolve(timecodes);
      },
      ({ error }) => {
        commit('getTimecodesFailure', error);
        commit('setLoading', false);
        return Promise.reject(error);
      }
    );
  },
  createTimecode({ commit, dispatch }, { programId, timecode }) {
    commit('setLoading', true);
    return programService.createTimecode(programId, timecode).then(
      (timecodes) => {
        commit('getTimecodeSuccess', { id: programId, timecodes });
        return Promise.resolve();
      },
      ({ error }) => {
        commit('getTimecodeFailure', error);
        const alert = {
          id: 'timecode-get-failure',
          icon: 'close',
          type: 'error',
          message: 'notifications.create.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
  updateTimecode({ commit, dispatch }, { id, programId, timecode }) {
    commit('setLoading', true);
    return programService.updateTimecode(id, programId, timecode).then(
      (timecodes) => {
        commit('updateTimecodeSuccess', { id: programId, timecodes });
        return Promise.resolve();
      },
      ({ error }) => {
        const alert = {
          id: 'timecode-update-failure',
          icon: 'close',
          type: 'error',
          message: 'notifications.update.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
  deleteTimecode({ commit, dispatch }, id) {
    commit('setLoading', true);
    return programService.deleteTimecode(id).then(
      (timecodes) => {
        commit('deleteTimecodeSuccess', timecodes);
        return Promise.resolve();
      },
      ({ error }) => {
        commit('deleteTimecodeFailure', error);
        const alert = {
          id: 'timecode-delete-failure',
          icon: 'close',
          type: 'error',
          message: 'notifications.delete.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },

  publish({ commit, dispatch }, program) {
    commit('publish', program);
    return Promise.resolve(program);
  },
  unpublish({ commit, dispatch }, program) {
    //await dispatch('unpublishSeasons'); //Season status should not be changed automatically when serie is disabled
    commit('unpublish', program);
    return Promise.resolve(program);
  },
  updatePublication({ state, commit, dispatch }, program) {
    let programID = program.id;
    if (state.loading) {
      return Promise.resolve();
    }
    commit('setLoadingDynamic', { type: 'program', value: true });
    const stateCurrentMovie = state.currentNewMovie;
    const publication = program.publication[state.countryCode];
    return programService
      .updatePublication(publication, programID, state.countryId)
      .then(
        async (currentProgram) => {
          if (!stateCurrentMovie || !stateCurrentMovie.id) {
            const index = state.all.data.findIndex((p) => p.id === programID);
            commit('updateMovieList', { index, currentProgram });
          } else {
            commit('getSuccess', currentProgram);
          }
          commit('setLoadingDynamic', { type: 'program', value: false });
          return Promise.resolve(currentProgram);
        },
        (error) => {
          const alert = {
            id: 'publication-confirmation',
            icon: 'close',
            type: 'error',
            message: 'notifications.update.error',
          };
          dispatch('displayAlert', alert, { root: true });
          commit('setLoadingDynamic', { type: 'program', value: false });
          return Promise.reject(error);
        }
      );
  },
  updatePublicationFromList({ state, commit, dispatch }, program) {
    let programID =
      program.type === 'episode'
        ? program.program_id
        : program.season_program_id;
    if (state.loading) {
      return Promise.resolve();
    }
    commit('setLoadingDynamic', { type: 'program', value: true });
    const stateCurrentMovie = state.currentNewMovie;
    const publication = program.publication[state.countryCode];
    return programService
      .updatePublication(publication, programID, state.countryId)
      .then(
        async (currentProgram) => {
          commit('setLoadingDynamic', { type: 'program', value: false });
          return Promise.resolve(currentProgram);
        },
        (error) => {
          const alert = {
            id: 'publication-confirmation',
            icon: 'close',
            type: 'error',
            message: 'notifications.update.error',
          };
          dispatch('displayAlert', alert, { root: true });
          commit('setLoadingDynamic', { type: 'program', value: false });
          return Promise.reject(error);
        }
      );
  },
  updateVideoAssets({ state, commit, dispatch }) {
    var promises = [];

    ['audios', 'subtitles'].forEach((assetType) => {
      let service = new GenericMediaService(assetType);
      const deltaAsset = state.videoAssetsDelta[assetType];
  
      deltaAsset.created.forEach((asset) => {
        promises.push(
          service.create(asset).then((response) => {
            commit('updateVideoAssetId', {
              partnerId: asset.video_environment,
              videoType: asset.video_type,
              videoId: asset.video_id,
              assetType: assetType,
              oldId: asset.id,
              newId: response.id,
            })
          })
        );
      });

      deltaAsset.deleted.forEach((asset) => {
        promises.push(service.delete(asset.id).then((response) => {
          commit('removeAssetFromDeleteList', { assetType: assetType, assetId: asset.id });
        }));
      });
    });

    if (promises.length === 0) {
      return Promise.resolve();
    }

    commit('setLoadingDynamic', { type: 'video_asset', value: true });

    return Promise.all(promises)
      .then(
        (response) => {
          return Promise.resolve();
        },
        (e) => {
          const alert = {
            id: 'video-asset-saved',
            icon: 'close',
            type: 'error',
            message: 'notifications.update.error',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(e);
        }
      ).finally(() => {
        commit('setLoadingDynamic', { type: 'video_asset', value: false });
      });
  },

  async search({ commit, dispatch, state, rootState }, [needle, queryString, config = null ]) {
    commit('setLoadingDynamic', { type: 'program', value: true });
    const serieSeasonsOrEpisodesSearch =
      queryString.indexOf('format=3') > -1 ||
      queryString.indexOf('format=4') > -1 ||
      queryString.indexOf('parent_collection_id') > -1;
    if (serieSeasonsOrEpisodesSearch && state.currentNewMovie?.id) {
      await dispatch('getStatus', state.currentNewMovie.id);
    }
    
    return programService.search(needle, queryString, config).then(
      (programs) => {
        commit('storeCountryInfos', rootState.platforms.currentPlatform);
        commit('getAllSuccess', programs);
        commit('setLoadingDynamic', { type: 'program', value: false });
        return Promise.resolve(programs);
      },
      (error) => {
        commit('setLoadingDynamic', { type: 'program', value: false });
        return Promise.reject(error);
      }
    );
  },
  deleteProgram({ commit, dispatch, rootState }, { id = null, clean = true }) {
    return programService.deleteProgram(id).then(
      () => {
        if (clean) {
          commit('cleanProgramStates');
        }
        const alert = {
          id: 'program-removed',
          icon: 'check',
          type: 'valid',
          message: 'notifications.delete.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (err) => {
        const alert = {
          id: 'program-removed',
          icon: 'close',
          type: 'error',
          message: 'notifications.delete.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(err);
      }
    );
  },

  export({ commit, rootState, dispatch }, id = null) {
    commit('setLoading', true);
    let payload = {
      user_id: rootState.auth.authUser.id,
      format: "xlsx",
      personal_token: rootState.auth.authUser?.tokens?.personal
    };
    if (id !== null) {
      payload.model_id = id;
    }
    return genericContentService.export(payload).then(
      () => {
        const alert = {
          id: 'videos-export-request-accepted',
          icon: 'check',
          type: 'valid',
          message: 'catalogue.pages.videos.search.export.alert.accepted',
        };
        dispatch('displayAlert', alert, { root: true });
        commit('setLoading', false);
        return Promise.resolve();
      },
      (error) => {
        const alert = {
          id: 'videos-export-request-rejected',
          icon: 'close',
          type: 'error',
          message: 'catalogue.pages.videos.search.export.alert.rejected',
        };
        dispatch('displayAlert', alert, { root: true });
        commit('setLoading', false);
        return Promise.reject(error);
      }
    );
  },

  cleanProgramStates({ commit }) {
    commit('cleanProgramStates');
  },

  async sendToPartners({ commit, dispatch, state, rootState }, payload) {
    const id = state.currentNewMovie.id;
    return programService.sendToPartners(id, payload).then(
      () => {
        dispatch('get', id);
        const alert = {
          id: 'send-to-partners',
          icon: 'check',
          type: 'valid',
          message: 'section.partners.modal.notifications.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (err) => {
        const alert = {
          id: 'send-to-partners',
          icon: 'close',
          type: 'error',
          message: 'section.partners.modal.notifications.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(err);
      }
    );
  },

  async deletePackages({ commit, dispatch, state  }, payload) {
    const id = state.currentNewMovie.id;
    return programService.deletePackages(id, payload).then(
      () => {
        dispatch('get', id);
        const alert = {
          id: 'delete-partner',
          icon: 'check',
          type: 'valid',
          message: 'notifications.delete.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (err) => {
        const alert = {
          id: 'delete-partner',
          icon: 'close',
          type: 'error',
          message: 'notifications.delete.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(err);
      }
    );
  },
  async unpublishPackage({ commit, dispatch, state }, payload) {
    const id = state.currentNewMovie.id;
    return programService.unpublishPackage(id, payload).then(
      () => {
        dispatch('get', id);
        const alert = {
          id: 'delete-partner',
          icon: 'check',
          type: 'valid',
          message: 'notifications.delete.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (err) => {
        const alert = {
          id: 'delete-partner',
          icon: 'close',
          type: 'error',
          message: 'notifications.delete.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(err);
      }
    );
  },

  getPartnersPackages({ commit, dispatch, state, rootState }) {
    const id = state.currentNewMovie.id;
    return programService.packagesStatuses(id).then(
      (packages) => {
        commit('setPartnersPackages', packages);
        return Promise.resolve(packages);
      },
      (err) => {
        const alert = {
          id: 'partner-packages',
          icon: 'close',
          type: 'error',
          message: 'section.partners.modal.notifications.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(err);
      }
    );
  },
  migratePartnersMetas({ commit, dispatch, state, rootState }) {
    const id = state.currentNewMovie.id;
    return programService.migratePartnersMetas(id).then(
      () => {
        dispatch('get', id);
        const alert = {
          id: 'migration-partner-metas',
          icon: 'check',
          type: 'valid',
          message: 'notifications.update.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (err) => {
        const alert = {
          id: 'migration-partner-metas',
          icon: 'close',
          type: 'error',
          message: 'notifications.update.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(err);
      }
    );
  },
};

const mutations = {
  updateField,
  setCurrentStatus(state, status) {
    state.currentNewMovie.status = status;
  },
  setCurrentNewMovie(state, payload) {
    state.currentNewMovie = cloneDeep(payload);
  },
  getAllSuccess(state, programs) {
    state.all = programs;
  },
  getCreateSuccess(state, program) {
    state.currentNewMovie = new Movie(program);
    state.savedNewMovie = new Movie(program);
  },
  getTimecodesSuccess(state, { id, timecodes }) {
    if (timecodes.length > 0) {
      timecodes.forEach((timecode) => {
        const newTimecode = new Timecode({ ...timecode, video_id: id });
        const existingCurrentTimecodeIndex =
          state.currentNewSerieTimecodes.findIndex((x) => x.id === timecode.id);
        if (existingCurrentTimecodeIndex > -1) {
          state.currentNewSerieTimecodes.splice(
            existingCurrentTimecodeIndex,
            1,
            newTimecode
          );
        } else {
          state.currentNewSerieTimecodes.push(newTimecode);
        }
        const existingSavedTimecodeIndex =
          state.savedNewSerieTimecodes.findIndex((x) => x.id === timecode.id);
        if (existingSavedTimecodeIndex > -1) {
          state.savedNewSerieTimecodes.splice(
            existingSavedTimecodeIndex,
            1,
            cloneDeep(newTimecode)
          );
        } else {
          state.savedNewSerieTimecodes.push(cloneDeep(newTimecode));
        }
      });
    } else {
      // INIT INTRO && OUTRO TIMECODES
      state.savedNewSerieTimecodes.push(
        new Timecode({ type: 'intro', video_id: id })
      );
      state.savedNewSerieTimecodes.push(
        new Timecode({ type: 'outro', video_id: id })
      );
      state.currentNewSerieTimecodes.push(
        new Timecode({ type: 'intro', video_id: id })
      );
      state.currentNewSerieTimecodes.push(
        new Timecode({ type: 'outro', video_id: id })
      );
    }
  },
  getTimecodesFailure(state) {
    state.currentNewSerieTimecodes = [];
    state.savedNewSerieTimecodes = [];
  },
  updateTimecodeField(state, data) {
    const { type, timecode } = data;
    timecode.start = utils.toSeconds(timecode.startTimecode);
    timecode.end = utils.toSeconds(timecode.endTimecode);
    const existingTimecodeIndex = state.currentNewSerieTimecodes.findIndex(
      (tc) => tc.type === type && tc.id === timecode.id
    );
    if (existingTimecodeIndex > -1) {
      state.currentNewSerieTimecodes.splice(existingTimecodeIndex, 1, timecode);
    }
  },
  updateTimecodeSuccess(state, timecode) {
    const existingTimecodeIndex = state.currentNewSerieTimecodes.findIndex(
      (x) => x.id === timecode.id
    );
    if (existingTimecodeIndex > -1) {
      state.currentNewSerieTimecodes.splice(existingTimecodeIndex, 1, timecode);
    } else {
      state.currentNewSerieTimecodes.push(timecode);
    }
  },
  updateLiveAttribute(state, { inputId, value }) {
    if (['pri_pull_url', 'pri_pull_user', 'pri_pull_pass'].includes(inputId)) {
      state.currentNewMovie.liveConfig.liveable[inputId] = value;
    } else {
      state.currentNewMovie.liveConfig[inputId] = value;
    }
  },
  getLiveConfigSuccess(state, config) {
    state.liveGlobalConfig = config;
  },
  getSuccess(state, program) {
    let parent = null;
    if (state.savedNewMovie?.parent) {
      parent = cloneDeep(state.savedNewMovie.parent);
    }
    const movie = new Movie(program);
    if (
      !movie?.publication ||
      (Array.isArray(movie.publication))
    ) {
      movie.publication = {};
      movie.publication[state.countryCode] = {
        country_id: state.countryId,
        is_free: false,
      };
    }
    movie.category = movie.category.map((x) => x.id);
    movie.tag = movie.tag.map((x) => x.id);
    movie.gear = movie.gear.map((x) => x.id);
    movie.country = movie.country.map((x) => x.id);
    if (
      movie.program_type_id === 5 &&
      movie?.live?.id
    ) {
      movie.live = movie.live.id;
    }
    if (movie.video.length === 0) {
      movie.video = {
        main: [],
        bonus: [],
        trailer: [],
      };
    }
    if (movie.picture.length === 0) {
      movie.picture = {
        backdrops: [],
        posters: [],
        banners: [],
        thumbnails: [],
        roundeds: [],
      };
    }
    if (!movie?.parent) {
      movie.parent = parent;
    }
    let pages = cloneDeep(state.pages);
    if (Object.keys(state.pages).length === 0) {
      if (movie.type === 'serie') {
        pages['serie_' + movie.serieId] = {
          current_page: 0,
          total_pages: 1,
          total: 0,
        };
      } else if (movie.type === 'season') {
        pages['season_' + movie.seasonId] = {
          current_page: 1,
          total_pages: 1,
          total: 0,
        };
      }
      state.pages = pages;
    }
    state.currentNewMovie = cloneDeep(movie);
    if (!state.currentPlatform) {
      state.currentPlatform = this.state.platforms.currentPlatform;
      state.currentMoviePublicationData.country_id = state.countryId;
    }
    
    state.savedNewMovie = movie;
    state.formatedCurrentNewMovie = {};
    state.videoAssetsDelta = { audios: { created: [], deleted: [] }, subtitles: { created: [], deleted: [] } };
  },

  setPartnersPackages(state, packages) {
    const transformer = new PartnersTransformer(packages);
    const ppackages = transformer.transformPartnersPackages();
    state.currentNewMovie.partnersPackages = ppackages;
    state.savedNewMovie.partnersPackages = cloneDeep(ppackages);
    
  },

  getFailure(state) {
    state.currentNewMovie = {};
    state.savedNewMovie = {};
    state.formatedCurrentNewMovie = {};
    state.videoAssetsDelta = { 
      audios: { created: [], deleted: [] }, 
      subtitles: { created: [], deleted: [] } 
    };
  },

  updateSuccess(state) {
    state.savedNewMovie = cloneDeep(state.currentNewMovie);
  },

  /**
   * Add participant from state new program
   *
   * @param {*} state
   * @param {*} payload => [partipantId, meta]
   * @since 2020-04-23  parameter should be array of person id and meta [JJ]
   */  
  addParticipant(state, { participant, meta, isPartner = false }) {
    if (isPartner) {
      if (state.currentNewMovie.partners_metas?.[meta]) {
        state.currentNewMovie.partners_metas[meta].push(participant);
      } else {
        state.currentNewMovie.partners_metas[meta] = [participant];
      }
    } else {
      state.currentNewMovie[meta].push(participant);
    }
  },
  /**
   * Remove particapant from state new program
   *
   * @param {*} state
   * @param {*} payload => [partipantId, meta]
   * @since 2020-04-23  parameter should be array of person id and meta [JJ]
   */
  removeParticipant(state, { participant, meta, isPartner = false }) {
    if (isPartner) {
      const i = state.currentNewMovie.partners_metas[meta].indexOf(participant);
      if (i > -1) {
        state.currentNewMovie.partners_metas[meta].splice(i, 1);
      }
    } else {
      const i = state.currentNewMovie[meta].indexOf(participant);
      if (i > -1) {
        state.currentNewMovie[meta].splice(i, 1);
      }
    }
  },

  /**
   * Add video to main program
   *
   * @param {*} state
   * @param {*} payload => [videos, seasonId, serieId]
   * @since 2020-06-23  videos should be an array of videos [TT]
   */
  addVideos(state, { videos, type, partnerId = null }) {
    videos = videos
      .map((x) => {
        x.activated = true;
        x.is_free = false;
        return x;
      })
      .sort((a, b) => {
        const a_sort_priority = a.target === 'default' ? 0 : 1;
        const b_sort_priority = b.target === 'default' ? 0 : 1;
        return a_sort_priority - b_sort_priority;
      });
    if (partnerId) {
      state.currentNewMovie[`${partnerId}_video`][type] = videos;
    } else {
      state.currentNewMovie.video[type] = videos;
    }
  },
  /**
   * Remove video from main program
   *
   * @param {*} state
   * @param {*} payload
   */
  removeVideo(state, { video, type, partnerId = null }) {
    const _video = partnerId
      ? state.currentNewMovie[`${partnerId}_video`][type]
      : state.currentNewMovie.video[type];
    const movieVideoIndex = _video.findIndex(
      (x) => x.id === video.id
    );
    if (movieVideoIndex > -1) {
      _video.splice(movieVideoIndex, 1);

      // If video is removed, we also remove the related audio and subtitle actions to proceed
      ['audios', 'subtitles'].forEach((assetType) => {
        ['created', 'deleted'].forEach((action) => {
          state.videoAssetsDelta[assetType][action] = state.videoAssetsDelta[assetType][action].filter(
            (asset) => asset.video_id !== video.id
          );
        });
      });
    }
  },
  updateMovieList(state, { index, currentProgram }) {
    state.all.data[index] = currentProgram;
  },

  /**
   * This section manages the asset (audio and subtitle) of the related video
   */
  addMovieVideoAsset(state, { partnerId, videoType, videoId, assetType, asset }) {
    const videoLabel = partnerId && partnerId !== 'otto' ? `${partnerId}_video` : 'video';
    asset.id = uuidv4();

    if (! ['audios', 'subtitles'].includes(assetType)) {
      console.error('Invalid asset type provided', assetType);
      return;
    }

    if (typeof state.currentNewMovie[videoLabel] === 'undefined') {
      state.currentNewMovie[videoLabel] = {};
    }
  
    if (typeof state.currentNewMovie[videoLabel][videoType] === 'undefined') {
      state.currentNewMovie[videoLabel][videoType] = [];
    }

    let videoIndex = state.currentNewMovie[videoLabel][videoType].findIndex((video) => video.id === videoId);

    if (videoIndex > -1) {
      if (typeof state.currentNewMovie[videoLabel][videoType][videoIndex][assetType] === 'undefined') {
        state.currentNewMovie[videoLabel][videoType][videoIndex][assetType] = [];
      }

      state.currentNewMovie[videoLabel][videoType][videoIndex][assetType].push(asset);
      state.videoAssetsDelta[assetType].created.push(asset);
    }
  },
  removeMovieVideoAsset(state, { partnerId, videoType, videoId, assetType, assetId }) {
    const videoLabel = partnerId && partnerId !== 'otto' ? `${partnerId}_video` : 'video';

    let videoIndex = state.currentNewMovie[videoLabel][videoType].findIndex((video) => video.id === videoId);

    if (videoIndex > -1) {
      let assetIndex = state.currentNewMovie[videoLabel][videoType][videoIndex][assetType].findIndex((asset) => asset.id === assetId);
      let asset = state.currentNewMovie[videoLabel][videoType][videoIndex][assetType][assetIndex];

      if (assetIndex > -1) {
        state.currentNewMovie[videoLabel][videoType][videoIndex][assetType].splice(assetIndex, 1);

        let tempIndex = state.videoAssetsDelta[assetType].created.findIndex((asset) => asset.id === assetId);
        if (tempIndex > -1) {
          state.videoAssetsDelta[assetType].created.splice(tempIndex, 1);
        } else {
          state.videoAssetsDelta[assetType].deleted.push(asset);
        }
      }
    }

  },
  updateVideoAssetId(state, { partnerId, videoType, videoId, assetType, oldId, newId }) {
    const videoLabel = partnerId && partnerId !== 'otto' ? `${partnerId}_video` : 'video';

    let videoIndex = state.currentNewMovie[videoLabel][videoType].findIndex((video) => video.id === videoId);

    if (videoIndex >= 0) {
      let assetIndex = state.currentNewMovie[videoLabel][videoType][videoIndex][assetType].findIndex((asset) => asset.id === oldId);

      if (assetIndex >= 0) {
        state.currentNewMovie[videoLabel][videoType][videoIndex][assetType][assetIndex].id = newId;

        let tempIndex = state.videoAssetsDelta[assetType].created.findIndex((asset) => asset.id === oldId);
        state.videoAssetsDelta[assetType].created.splice(tempIndex, 1);
      }
    }
  },
  removeAssetFromDeleteList(state, { assetType, assetId }) {
    let index = state.videoAssetsDelta[assetType].deleted.findIndex((asset) => asset.id === assetId);

    if (index >= 0) {
      state.videoAssetsDelta[assetType].deleted.splice(index, 1);
    }
  },

  publish(state, program) {
    program = program ? program : state.currentNewMovie;
    if (!program.publication[state.countryCode]) {
      program.publication[state.countryCode] = {
        country_id: state.countryId,
        is_free: 0,
      };
    }
    program.publication[state.countryCode].publish_at = moment().utc().format();
    program.publication[state.countryCode].publish_at_utc = moment().utc().format();
    program.publication[state.countryCode].publish_now = true;
  },
  unpublish(state, program) {
    program = program ? program : state.currentNewMovie;
    if (!program.publication[state.countryCode]) {
      program.publication[state.countryCode] = {
        country_id: state.countryId,
        is_free: 0,
      };
    }
    program.publication[state.countryCode].publish_at = null;
    program.publication[state.countryCode].publish_at_utc = null;
    program.publication[state.countryCode].publish_now = false;
  },
  updatePublishAt(state, value) {
    state.currentNewMovie.publication[state.countryCode].publish_at = value;
    state.currentNewMovie.publication[state.countryCode].publish_at_utc = value 
      ? moment(value).utc().format() 
      : null;

  },
  updateUnpublishAt(state, value) {
    state.currentNewMovie.publication[state.countryCode].unpublish_at = value;
    state.currentNewMovie.publication[state.countryCode].unpublish_at_utc = value 
      ? moment(value).utc().format() 
      : null;
  },
  updatePublishNow(state, value) {
    state.currentNewMovie.publication[state.countryCode].publish_now = value;
  },
  updateCurrentNewMovieMeta(state, { metaId, payload, isPartner = false }) {
    if (isPartner) {
      state.currentNewMovie.partners_metas[metaId] = payload;
    } else {
      state.currentNewMovie[metaId] = payload;
    }
  },

  updateCurrentNewMovieImage(state, payload) {
    var image = {
      ...payload,
    };
    if (state.currentNewMovie?.picture?.[payload?.collectionName]) {
      state.currentNewMovie.picture[payload.collectionName].splice(0, 1);
    }
    state.currentNewMovie.picture = Object.assign(
      {},
      state.currentNewMovie.picture,
      { [payload.collectionName]: [image] }
    );
  },
  removeCurrentNewMovieImage(state, image) {
    const currentType = image?.collectionName;
    if (state.currentNewMovie.picture?.[currentType]) {
      state.currentNewMovie.picture[currentType].splice(0, 1);
      const currentTypeSettings = picturesTypes.find(
        (movieCollectionType) => movieCollectionType.type === currentType
      );
      if (currentTypeSettings?.declinations) {
        currentTypeSettings.declinations.forEach((declination) => {
          if (declination in state.currentNewMovie.picture) {
            state.currentNewMovie.picture[declination].splice(0, 1);
          }
        });
      }
    }
  },
  toggleEpisodeIsFree(state, { episode, value }) {
    if (
      !episode.publication ||
      typeof episode.publication.is_free === 'undefined'
    ) {
      episode.publication = {
        is_free: 0,
      };
    }
    episode.publication.is_free = value === true ? 1 : 0;
  },
  toggleLoadingAmazon(state, status) {
    state.loadingAmazon = status;
  },

  updateLiveSuccess(state, liveId) {
    state.formatedCurrentNewMovie.live = liveId;
    state.currentNewMovie.liveConfig.id = liveId;
    state.currentNewMovie.liveConfig.newLive = false;
    state.savedNewMovie.liveConfig.id = liveId;
    state.savedNewMovie.liveConfig.newLive = false;
  },

  searchRequest(state) {
    state.all = {
      data: [],
      meta: {
        pagination: {
          total_pages: 0,
          per_page: 0,
          total: 0,
          count: 0,
          current_page: 1,
        },
      },
    };
  },
  formatProgramData(state, [metadata, currentPlatform]) {
    //Use transformer so that we can have exactly the same behaviour for series/seasons/episodes/movies
    const transformer = new ProgramTransformer(
      cloneDeep(state.currentNewMovie),
      metadata,
      currentPlatform
    );
    state.formatedCurrentNewMovie = transformer.transform();
  },
  cleanProgramStates(state) {
    state.all = {
      data: [],
      meta: {
        pagination: {
          total_pages: 0,
          per_page: 0,
          total: 0,
          count: 0,
          current_page: 1,
        },
      },
    };
    state.currentNewMovie = {};
    state.savedNewMovie = {};
    state.formatedCurrentNewMovie = {};
    state.pendingPictureUpdates = [];
    state.linked = {};
    state.videoAssetsDelta = {audios: {created: [], deleted: []}, subtitles: {created: [], deleted: []}};
  },
  setLoading(state, value) {
    state.loading = value;
  },
  setLoadingDynamic(state, { type, value }) {
    switch (type) {
      case 'program':
        state.loading = value;
        break;
      case 'serie':
        state.loadingSerie = value;
        break;
      case 'season':
        state.loadingSeason = value;
        break;
      case 'episode':
        state.loadingEpisode = value;
        break;
      case 'subtitle':
        state.loadingSubtitle = value;
        break;
      default:
        //ignore
        break;
    }
  },
  setStatus(state, status) {
    state.status = status;
    state.linked = status.linked;
  },
  updateProgramIsFree(state, value) {
    state.currentNewMovie.publication[state.countryCode].is_free = value;
  },
  storeCountryInfos(state, platform) {
    if(platform) {
      state.countryCode = platform.getCountryCode();
      state.countryId = platform.getCountryId();
    }
  },
  updateRefreshDateKey(state) {
    state.refreshDateKey = Math.random();
  },

  updateFields(state, { field, value }) {
    state.currentNewMovie[field] = value;
  },
  updateSavedNewMovieFields(state, { field, value }) {
    state.savedNewMovie[field] = value;
  },

  /*********** PARTNERS ********/
  // update partner publication
  updatePartnersPublications(state, { partnerId, country, field, value }) {
    const pub = state.currentNewMovie?.partners_publications?.[partnerId]
      ? state.currentNewMovie.partners_publications[partnerId]
        .find((p) => p.country === country)
      : null;
    if(pub) {
      if (field === 'enabled') {
        pub['rights_ends_at'] = null;
      }
      pub[field] = value;
    }
  },
};

export const programs = {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
